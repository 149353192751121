import { PlusCircleOutlined } from '@ant-design/icons';
import { Badge, Button, Radio, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { mongoClient } from '../../apollo';
import {
  CATEGORIES_KEYS,
  LIMIT,
  MODULES,
  PAGE_SIZE_OPTIONS,
  SORT,
  SORT_OPTION,
  TEMPLATE_STATUS_COLOR,
} from '../../common/constants';
import SearchComponent from '../../components/SearchComponent';
import SectionsModal from './components/SectionModal';
import GET_SECTIONS from './graphql/queries';

const { Title } = Typography;
const CHECK_ARRAY_FILTER = [
  {
    text: 'Yes',
    value: true,
  },
  {
    text: 'No',
    value: false,
  },
];
const initialFormValue = { label: '', considerCheckArray: false };
function SectionsList() {
  const { category } = useParams();

  const [variables, setVariables] = useState({
    filter: {
      skip: 0,
      limit: LIMIT,
      search: '',
    },
    sort: { sortBy: 'DESC', sortOn: 'createdAt' },
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: LIMIT,
    pageSizeOptions: PAGE_SIZE_OPTIONS,
  });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formPopup, setFormPopup] = useState({
    isOpen: false,
    id: null,
  });
  const [initialFormData, setInitialFormData] = useState(initialFormValue);

  const fetchData = async (variablesObj = variables, pageNumber = 1) => {
    setLoading(true);
    try {
      const listData = await mongoClient?.query({
        query: GET_SECTIONS,
        fetchPolicy: 'network-only',
        variables: {
          filter: {
            ...variablesObj?.filter,
            category: CATEGORIES_KEYS[category],
          },
          sort: { ...variablesObj?.sort },
        },
      });
      setData(listData?.data?.sectionsAdmin?.data);
      setPagination({
        ...pagination,
        total: listData?.data?.sectionsAdmin?.count,
        current: pageNumber,
        pageSize: variablesObj?.filter?.limit,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [category]);

  const handleSearchChange = (e) => {
    const prepareFilter = {
      ...variables,
      filter: { ...variables.filter, search: e },
    };
    setVariables(prepareFilter);
    fetchData(prepareFilter);
  };

  const handleTableChange = (paginationValue, filters, sorter) => {
    const pageSizeValue = paginationValue?.pageSize ?? LIMIT;
    const prepareFilter = {
      ...variables,
      filter: {
        ...variables.filter,
        skip: (paginationValue?.current - 1) * pageSizeValue,
        limit: pageSizeValue,
        considerCheckArray:
          filters?.considerCheckArray && filters?.considerCheckArray?.length
            ? filters?.considerCheckArray?.[0]
            : undefined,
      },
      sort: {
        sortOn:
          sorter.columnKey && sorter.order ? sorter.columnKey : 'createdAt',
        sortBy: sorter.order === SORT.ASC ? SORT_OPTION.ASC : SORT_OPTION.DESC,
      },
    };

    fetchData(prepareFilter, paginationValue?.current);
  };

  const handleClose = () => {
    setFormPopup({
      isOpen: false,
      id: null,
    });
  };

  const handleForm = async () => {
    try {
      await fetchData();
    } catch (error) {
      return;
    } finally {
      handleClose();
    }
  };
  const getCheckArrayStatusFilterProps = () => ({
    filterMultiple: false,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        {CHECK_ARRAY_FILTER?.map((option) => (
          <div key={option?.text} className="mt-6 mb-6">
            <Radio
              value={option?.value}
              checked={selectedKeys.includes(option?.value)}
              onChange={(e) => {
                const updatedKeys = e?.target?.checked ? [option?.value] : [];
                setSelectedKeys(updatedKeys);
              }}
            >
              {option?.text}
            </Radio>
          </div>
        ))}
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            onClick={() => {
              confirm();
            }}
            className="ok-button"
          >
            Apply
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
          >
            Reset
          </Button>
        </div>
      </div>
    ),
  });

  return (
    <>
      <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
        {MODULES.SECTIONS}
        {pagination?.total ? ` (${pagination?.total})` : ''}
      </Title>
      <div className="filter-input mb-12">
        <SearchComponent className="list-search" getData={handleSearchChange} />
        <Button
          icon={<PlusCircleOutlined />}
          className="ml-8"
          key="1"
          type="primary"
          onClick={() => {
            setFormPopup({
              isOpen: true,
              id: 'new',
            });
            setInitialFormData(initialFormValue);
          }}
        >
          Add Section
        </Button>
      </div>
      <Table
        columns={[
          {
            title: 'Name',
            sorter: true,
            width: '40%',
            key: 'label',
            dataIndex: 'label',
          },
          {
            title: 'Consider this Label for Sufix/Prefix section match',
            render: (item) =>
              item?.considerCheckArray ? (
                <Badge count="Yes" color={TEMPLATE_STATUS_COLOR.PUBLISHED} />
              ) : (
                <Badge count="No" color={TEMPLATE_STATUS_COLOR.UNPUBLISHED} />
              ),
            key: 'considerCheckArray',
            ...getCheckArrayStatusFilterProps(),
          },
        ]}
        // eslint-disable-next-line arrow-body-style
        onRow={(record) => {
          return {
            onClick: () => {
              setFormPopup({
                isOpen: true,
                id: record?.id,
              });
              setInitialFormData({
                label: record?.label,
                considerCheckArray: Boolean(record?.considerCheckArray),
              });
            },
          };
        }}
        rowKey={(record) => record?.id}
        dataSource={data}
        pagination={pagination}
        scroll={{ y: `calc(100vh - 342px)` }}
        loading={loading}
        onChange={handleTableChange}
        rowClassName="pointer"
      />
      <SectionsModal
        formPopup={formPopup}
        handleCloseModal={handleClose}
        handleModalForm={handleForm}
        initialFormData={initialFormData}
        category={category}
      />
    </>
  );
}

export default SectionsList;
