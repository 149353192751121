import { useLazyQuery } from '@apollo/client';
import { Button, DatePicker, Table, Tag, Typography } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { LIMIT, PAGE_SIZE_OPTIONS } from '../../common/constants';
import { disableFutureDate } from '../../common/utils';
import SearchComponent from '../../components/SearchComponent';
import QuotationDetails from './components/QuotationDetails';
import { GET_QUOTATIONS } from './graphql/queries';

const { Title } = Typography;
const { RangePicker } = DatePicker;

function QuotationList() {
  const [showModal, setShowModal] = useState(false);
  const [orderDetails, setOrderDetails] = useState();
  const [filter, setFilter] = useState({
    limit: LIMIT,
    skip: 1,
    search: '',
    startTime: '',
    endTime: '',
    type: 'ONE_TO_ONE',
  });
  const [sort, setSort] = useState({ sortBy: 'DESC', sortOn: 'createdAt' });

  const [fetchQuotations, { data: quotations, loading }] = useLazyQuery(
    GET_QUOTATIONS,
    {
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  useEffect(() => {
    const { skip, limit, status, type, startTime, endTime, search } = filter;
    fetchQuotations({
      variables: {
        filter: {
          search,
          skip: (skip - 1) * limit,
          limit,
          status,
          type,
          startTime,
          endTime,
        },
        sort,
      },
    });
  }, [filter, sort]);

  const handleSearchChange = (value) => {
    setFilter({ ...filter, skip: 1, search: value });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setFilter({
      ...filter,
      skip: pagination?.current,
      limit: pagination?.pageSize,
    });
    setSort({
      ...sort,
      sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
      sortOn: sorter?.field ?? 'createdAt',
    });
  };

  const handleDatePicker = (date) => {
    setFilter({
      ...filter,
      skip: 1,
      startTime: date?.[0] && dayjs(date?.[0])?.startOf('day').format(),
      endTime: date?.[1] && dayjs(date?.[1])?.endOf('day').format(),
    });
  };

  const columns = [
    {
      title: 'Time',
      dataIndex: 'Time',
      render: (text, record) =>
        `${dayjs(record?.booking?.startTime).format('HH:mm')} to ${dayjs(
          record?.booking?.endTime,
        ).format('HH:mm')}`,
    },
    {
      title: 'Consultant',
      dataIndex: 'consultant',
      render: (text, record) =>
        `${record?.booking?.consultationCall?.creator?.firstName} ${record?.booking?.consultationCall?.creator?.lastName}`,
    },
    {
      title: 'Service Name',
      dataIndex: 'serviceName',
      width: '15%',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      ellipsis: true,
    },
    {
      title: 'Price',
      dataIndex: 'price',
      align: 'right',
      render: (price) => `₹ ${price}`,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      align: 'center',
      render: (text, record) => (
        <Tag color="processing">
          {record?.booking?.consultationCall?.type === 'ONE_TO_ONE'
            ? '1-to-1'
            : 'Webinar'}
        </Tag>
      ),
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      render: (record) => (
        <Button
          type="link"
          onClick={() => {
            setShowModal(true);
            setOrderDetails(record);
          }}
        >
          View Details
        </Button>
      ),
    },
  ];

  return (
    <>
      <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
        Quotations
        {quotations?.quotationsAdmin?.count
          ? ` (${quotations?.quotationsAdmin?.count})`
          : ''}
      </Title>
      <div className="d-flex gap-12 filter-input mb-12">
        <SearchComponent className="list-search" getData={handleSearchChange} />
        <div className="d-flex gap-12">
          <RangePicker
            onChange={handleDatePicker}
            disabledDate={disableFutureDate}
          />
        </div>
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record?.key}
        dataSource={quotations?.quotationsAdmin?.data}
        onChange={handleTableChange}
        loading={loading}
        scroll={{ y: `calc(100vh - 342px)` }}
        pagination={{
          total: quotations?.quotationsAdmin?.count,
          current: filter?.skip,
          pageSize: filter?.limit,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
        }}
      />
      <QuotationDetails
        setShowModal={setShowModal}
        showModal={showModal}
        orderDetails={orderDetails}
      />
    </>
  );
}

export default QuotationList;
