import dayjs from 'dayjs';
import { includes, map } from 'lodash';
import React from 'react';
import { CallNotificationIcon, ServiceBox } from '../../../assets/svg';
import { ROUTES, RefDataType, dateTimeFormat } from '../../../common/constants';
import useRouter from '../../../hooks/useRouter';
import '../../../styles/components/notification.less';

export default function NotificationDrawer({ notifications, toggleDrawer }) {
  const { navigate } = useRouter();

  const notificationIcon = (type) => {
    switch (type) {
      case RefDataType.call:
        return <CallNotificationIcon />;
      default:
        return <ServiceBox />;
    }
  };

  const handleRedirection = (refData) => {
    const { type, slug, category, id } = refData;

    let targetModule;

    switch (type) {
      case RefDataType.call:
        targetModule = `${ROUTES.CONSULTATION_CALLS}/view/${id}`;
        break;
      case RefDataType.expertSummary:
        targetModule =
          typeof category === 'string'
            ? `${ROUTES?.[category]}${ROUTES.EDIT_JUDGEMENTS}${id}`
            : '';
        break;
      case RefDataType.template:
        targetModule =
          typeof category === 'object' ? `${ROUTES.TEMPLATE}/edit/${id}` : '';
        break;
      default:
        targetModule = ROUTES.HOME;
        break;
    }

    if (slug) {
      navigate(targetModule);
      toggleDrawer();
    }
  };

  return (
    <ul className="notification-list">
      {map(notifications, (notification) => {
        const typeArr = [
          RefDataType.call,
          RefDataType.expertSummary,
          RefDataType.judgement,
          RefDataType.template,
        ];
        const isClickable = includes(typeArr, notification?.refData?.type);

        return (
          <li
            key={notification?.id}
            className={`d-flex gap-12 notification-list-item border-bottom ${
              !notification?.readAt ? 'is-not-read' : ''
            } ${isClickable ? 'pointer' : ''}`}
            onClick={() => handleRedirection(notification?.refData)}
          >
            <span className="d-flex">
              {notificationIcon(notification?.refData?.type)}
            </span>
            <div className="notification-content">
              <p className="body-m semibold notification-title">
                {notification?.title}
                <span>
                  - {dayjs(notification?.createdAt).format(dateTimeFormat)}
                </span>
              </p>
              <span>{notification?.content}</span>
            </div>
          </li>
        );
      })}
    </ul>
  );
}
