import { useLazyQuery } from '@apollo/client';
import { Button, Checkbox, DatePicker, Table, Tabs, Typography } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import {
  dateTimeFormat,
  defaultDateFormat,
  FEATURE_NOTICE_BOARD_DIRECT_TAX,
  FEATURE_NOTICE_BOARD_INDIRECT_TAX,
  LIMIT,
  MODULES,
  NOTICE_BOARD_NAME,
  PAGE_SIZE_OPTIONS,
  PAGE_TYPE,
  SORT,
  SUBSCRIPTION_DOWNLOADABLE,
  TAX_CATEGORIES,
} from '../../common/constants';
import { disableFutureDate } from '../../common/utils';
import SearchComponent from '../../components/SearchComponent';
import {
  FETCH_PLAN_PURCHASED_LIST,
  FETCH_TEMPLATES_PURCHASED_LIST,
} from './graphql/queries';
import './payment.less';

const { Title } = Typography;
const { RangePicker } = DatePicker;

const TABS = [
  {
    key: PAGE_TYPE.PLAN,
    label: 'Plans',
  },
  {
    key: PAGE_TYPE.TEMPLATE,
    label: 'Templates',
  },
];

const PLAN_TYPE_FILTER = [
  {
    text: 'Direct Tax',
    value: 'DIRECT_TAX',
  },
  {
    text: 'Indirect Tax',
    value: 'INDIRECT_TAX',
  },
  {
    text: 'Company Law',
    value: 'COMPANY_LAW',
  },
  {
    text: 'Templates',
    value: 'TEMPLATES',
  },
  { text: 'Noticeboard - Direct Tax', value: 'NOTICE_BOARD_DIRECT_TAX' },
  /* kept for future use */
  /* { text: 'Noticeboard - Indirect Tax', value: 'NOTICE_BOARD_INDIRECT_TAX' }, */
];

const initialFilterPlans = {
  filter: {
    endDate: null,
    limit: LIMIT,
    search: null,
    skip: 0,
    startDate: null,
  },
  sort: [
    {
      sortOn: 'createdAt',
      sortBy: 'DESC',
    },
  ],
};
const initialFilterTemplates = {
  filter: {
    endDate: null,
    limit: LIMIT,
    search: null,
    skip: 0,
    startDate: null,
    categoryId: null,
    subCategoryId: null,
  },
  sort: [
    {
      sortOn: 'createdAt',
      sortBy: 'DESC',
    },
  ],
};
const initialPagination = {
  pagination: {
    pageSizeOptions: PAGE_SIZE_OPTIONS,
    current: 1,
    pageSize: LIMIT,
  },
};
function PaymentList() {
  const [params, setParams] = useState(initialFilterPlans);

  const [list, setList] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedTabKey, setSelectedTabKey] = useState(PAGE_TYPE.PLAN);
  const [tableParams, setTableParams] = useState(initialPagination);
  const [dateValue, setDateValue] = useState(null);

  const [fetchSubsctiptionPurchsedList] = useLazyQuery(
    FETCH_PLAN_PURCHASED_LIST,
  );
  const [fetchTemplatePurchsedList] = useLazyQuery(
    FETCH_TEMPLATES_PURCHASED_LIST,
  );

  function fetchSubsctiptionPurchsedListCall(
    filterValue = params,
    paginationObj = tableParams.pagination,
  ) {
    setLoading(true);
    fetchSubsctiptionPurchsedList({
      variables: filterValue,
      fetchPolicy: 'network-only',
      onCompleted: ({ subscriptionPlanPurchasesAdmin }) => {
        setList(subscriptionPlanPurchasesAdmin?.data);
        const count = subscriptionPlanPurchasesAdmin?.count;
        setTableParams({
          ...tableParams,
          pagination: {
            ...paginationObj,
            total: count,
            current: paginationObj.current,
          },
        });
        setLoading(false);
      },
      onError: () => {
        setLoading(false);
      },
    });
  }
  function fetchTemplatePurchsedListCall(
    filterValue = params,
    paginationObj = tableParams.pagination,
  ) {
    setLoading(true);
    fetchTemplatePurchsedList({
      variables: filterValue,
      fetchPolicy: 'network-only',
      onCompleted: ({ templatePurchasesAdmin }) => {
        setList(templatePurchasesAdmin?.data);
        const count = templatePurchasesAdmin?.count;
        setTableParams({
          ...tableParams,
          pagination: {
            ...paginationObj,
            total: count,
            current: paginationObj.current,
          },
        });
        setLoading(false);
      },
      onError: () => {
        setLoading(false);
      },
    });
  }

  useEffect(() => {
    fetchSubsctiptionPurchsedListCall();
  }, []);

  const handleTableChange = (pagination, filters, sorter) => {
    const prepareFilter = {
      filter: {
        ...params?.filter,
        skip: (pagination?.current - 1) * pagination?.pageSize,
        limit: pagination?.pageSize || LIMIT,
        categories: filters?.categories ? filters?.categories : [],
      },
      sort: [
        {
          sortOn: Object?.values(sorter)?.length ? sorter?.field : 'createdAt',
          sortBy: sorter?.order === SORT.ASC ? 'ASC' : 'DESC',
        },
      ],
    };
    const preparePagination = {
      ...tableParams?.pagination,
      current: pagination?.current,
      pageSize: pagination?.pageSize || LIMIT,
    };
    if (selectedTabKey === PAGE_TYPE.PLAN) {
      setParams(prepareFilter);
      fetchSubsctiptionPurchsedListCall(prepareFilter, preparePagination);
    } else {
      const prepareFilterObj = {
        ...prepareFilter,
        filter: {
          ...prepareFilter.filter,
          categoryId: filters?.categoryId ? filters?.categoryId : null,
          subCategoryId: filters?.subCategoryId ? filters?.subCategoryId : null,
        },
        sort: [
          {
            sortOn: Object?.values(sorter)?.length
              ? sorter?.field
              : 'createdAt',
            sortBy: sorter?.order === SORT.ASC ? 'ASC' : 'DESC',
          },
        ],
      };
      if (prepareFilterObj.filter.categories) {
        delete prepareFilterObj.filter.categories;
      }
      setParams(prepareFilterObj);
      fetchTemplatePurchsedListCall(prepareFilterObj, preparePagination);
    }
  };

  const handleSearchChange = (e) => {
    const prepareFilter = {
      ...params,
      filter: { ...params.filter, search: e, skip: 0 },
    };
    setParams(prepareFilter);
    if (selectedTabKey === PAGE_TYPE.PLAN) {
      fetchSubsctiptionPurchsedListCall(prepareFilter);
    } else {
      fetchTemplatePurchsedListCall(prepareFilter);
    }
  };

  const getPlanFilter = () => ({
    filterMultiple: true,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        {PLAN_TYPE_FILTER.map((option) => (
          <div key={option?.value} className="mt-6 mb-6">
            <Checkbox
              checked={selectedKeys.includes(option?.value)}
              onChange={(e) => {
                const updatedKeys = e.target.checked
                  ? [...selectedKeys, option.value]
                  : selectedKeys.filter((key) => key !== option.value);
                setSelectedKeys(updatedKeys);
              }}
            >
              {option?.text}
            </Checkbox>
          </div>
        ))}
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            onClick={() => {
              confirm();
            }}
            className="ok-button"
          >
            Apply
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
          >
            Reset
          </Button>
        </div>
      </div>
    ),
  });

  /* this code can be used in future */
  /*  const getCategoryFilter = () => ({
    filterMultiple: true,
    filterSearch: true,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        <div className="mt-6 mb-6">
          <CommonSelect
            value={selectedKeys}
            placeholder="Select Category"
            className="full-width provider-select-filter"
            showSearch
            allowClear
            onChange={(item) => {
              setSelectedKeys(item);
            }}
            query={GET_CATEGORIES}
            fetchPolicy="network-only"
            responsePath="templateCategoriesAdmin.data"
            valuePath="id"
            labelPath="name"
            variables={{
              filter: {
                limit: 50,
                search: '',
                skip: 0,
              },
              sort: [
                {
                  sortBy: 'DESC',
                  sortOn: 'order',
                },
              ],
            }}
          />
        </div>
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            onClick={() => {
              confirm();
            }}
            className="ok-button"
          >
            Apply
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
          >
            Reset
          </Button>
        </div>
      </div>
    ),
  }); */

  /* this code can be used in future */
  /*  const getSubCategoryFilter = () => ({
    filterMultiple: true,
    filterSearch: true,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        <div className="mt-6 mb-6">
          <CommonSelect
            value={selectedKeys}
            placeholder="Select Sub Category"
            className="full-width provider-select-filter"
            showSearch
            allowClear
            onChange={(item) => {
              setSelectedKeys(item);
            }}
            query={GET_CATEGORIES}
            fetchPolicy="network-only"
            responsePath="templateCategoriesAdmin.data"
            valuePath="id"
            labelPath="name"
            variables={{
              where: {
                id: params?.filter?.categoryId
                  ? params?.filter?.categoryId
                  : null,
              },
              filter: {
                limit: 50,
                search: '',
                skip: 0,
              },
              sort: [
                {
                  sortBy: 'DESC',
                  sortOn: 'order',
                },
              ],
            }}
          />
        </div>
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            onClick={() => {
              confirm();
            }}
            className="ok-button"
          >
            Apply
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
          >
            Reset
          </Button>
        </div>
      </div>
    ),
  }); */

  const handleDatePicker = (date) => {
    setDateValue(date);
    const prepareFilters = {
      ...params,
      filter: {
        ...params.filter,
        skip: 0,
        startDate: date && dayjs(date?.[0])?.startOf('day'),
        endDate: date && dayjs(date?.[1])?.endOf('day'),
      },
    };
    if (selectedTabKey === PAGE_TYPE.PLAN) {
      fetchSubsctiptionPurchsedListCall(prepareFilters);
    } else {
      fetchTemplatePurchsedListCall(prepareFilters);
    }
    setParams(prepareFilters);
  };

  const onTabChanges = (key) => {
    setLoading(true);
    setSelectedTabKey(key);
    setDateValue(null);
    setTableParams({
      pagination: {
        ...tableParams.pagination,
        current: 1,
        pageSize: LIMIT,
      },
    });
    if (key === PAGE_TYPE.PLAN) {
      fetchSubsctiptionPurchsedListCall(
        initialFilterPlans,
        initialPagination.pagination,
      );
      setParams(initialFilterPlans);
    } else {
      fetchTemplatePurchsedListCall(
        initialFilterTemplates,
        initialPagination.pagination,
      );
      setParams(initialFilterTemplates);
    }
  };

  return (
    <>
      <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
        {MODULES.PAYMENTS}
        {tableParams?.pagination?.total
          ? ` (${tableParams?.pagination?.total})`
          : ''}
      </Title>
      <div className="d-flex gap-12 filter-input mb-12">
        <SearchComponent className="list-search" getData={handleSearchChange} />
        <div className="d-flex gap-12">
          <RangePicker
            onChange={handleDatePicker}
            disabledDate={disableFutureDate}
            value={dateValue}
          />
        </div>
      </div>
      <Tabs defaultActiveKey="1" items={TABS} onChange={onTabChanges} />
      {selectedTabKey === PAGE_TYPE.PLAN && (
        <Table
          columns={[
            {
              title: 'Purchaser',
              render: (item) =>
                `${item?.purchaser?.firstName} ${item?.purchaser?.lastName}`,
            },
            {
              title: 'Phone Number',
              render: (item) => item?.purchaser?.phoneNumber,
            },
            {
              title: 'Plan',
              render: (item) => {
                const indirectTax = item?.features?.includes(
                  FEATURE_NOTICE_BOARD_INDIRECT_TAX,
                )
                  ? `${NOTICE_BOARD_NAME} - ${TAX_CATEGORIES.INDIRECT_TAX} - ${item?.subscriptionPlan?.title}`
                  : item?.subscriptionPlan?.title;
                return item?.features?.includes(FEATURE_NOTICE_BOARD_DIRECT_TAX)
                  ? `${NOTICE_BOARD_NAME} - ${TAX_CATEGORIES.DIRECT_TAX} - ${item?.subscriptionPlan?.title}`
                  : indirectTax;
              },
              key: 'categories',
              ...getPlanFilter(),
            },
            {
              title: 'Created On',
              dataIndex: 'createdAt',
              sorter: true,
              defaultSortOrder: 'descend',
              render: (value) => (
                <span>
                  {value ? dayjs(value)?.format(dateTimeFormat) : '-'}
                </span>
              ),
            },
            {
              title: 'Purchase Date',
              render: (item) =>
                item?.createdAt
                  ? dayjs(item?.createdAt).format(defaultDateFormat)
                  : '-',
            },
            {
              title: 'Expiry Date',
              render: (item) =>
                item?.expireDate
                  ? dayjs(item?.expireDate)?.format(defaultDateFormat)
                  : '-',
            },
            {
              title: 'Downloads/Used',
              render: (item) => (item?.usageCount ? item?.usageCount : '-'),
            },
            {
              title: 'Count/Credit',
              render: (item) =>
                item?.features?.length === 1
                  ? item?.featureConfig[
                      SUBSCRIPTION_DOWNLOADABLE?.[item?.features?.[0]]
                    ]
                  : item?.featureConfig?.[SUBSCRIPTION_DOWNLOADABLE.ALL_IN_ONE],
            },
            {
              title: 'Price (INR/Year)',
              render: (item) =>
                item?.billing?.price ? item?.billing?.price?.toFixed(2) : '-',
            },
          ]}
          rowKey={(record) => record?.id}
          dataSource={list}
          pagination={tableParams?.pagination}
          scroll={{ y: `calc(100vh - 342px)` }}
          loading={loading}
          onChange={handleTableChange}
        />
      )}
      {selectedTabKey === PAGE_TYPE.TEMPLATE && (
        <Table
          columns={[
            {
              title: 'Purchaser',
              render: (item) =>
                `${item?.purchaser?.firstName} ${item?.purchaser?.lastName}`,
            },
            {
              title: 'Phone Number',
              render: (item) => item?.purchaser?.phoneNumber,
            },
            {
              title: 'Template Title',
              render: (item) => item?.template?.title,
            },
            {
              title: 'Created On',
              dataIndex: 'createdAt',
              sorter: true,
              defaultSortOrder: 'descend',
              render: (value) => (
                <span>
                  {value ? dayjs(value)?.format(dateTimeFormat) : '-'}
                </span>
              ),
            },
            {
              title: 'Purchase Date',
              render: (item) =>
                item?.createdAt
                  ? dayjs(item?.createdAt).format(defaultDateFormat)
                  : '-',
            },
            {
              title: 'Expiry Date',
              render: (item) =>
                item?.expireDate
                  ? dayjs(item?.expireDate)?.format(defaultDateFormat)
                  : '-',
            },
            //   /* this code can be used in future */
            // {
            //   title: 'Category',
            //   filterSearch: true,
            //   render: (item) => item?.template?.category?.name,
            //   key: 'categoryId',
            //   // ...getCategoryFilter(),
            // },
            // {
            //   title: 'Sub Category',
            //   render: (item) => item?.template?.subCategory?.name,
            //   filterSearch: true,
            //   key: 'subCategoryId',
            //   // ...getSubCategoryFilter(),
            // },
            {
              title: 'Price (INR/Year)',
              render: (item) =>
                item?.billing?.price
                  ? item?.billing?.price
                  : item?.template?.price ?? '-',
            },
          ]}
          rowKey={(record) => record?.id}
          dataSource={list}
          pagination={tableParams?.pagination}
          scroll={{ y: `calc(100vh - 342px)` }}
          loading={loading}
          onChange={handleTableChange}
        />
      )}
    </>
  );
}

export default PaymentList;
