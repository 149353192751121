import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import React, { useContext, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { mongoClient } from '../../../apollo';
import { AppContext } from '../../../AppContext';
import {
  CATEGORIES_KEYS,
  COURT_LIST,
  dateTimeFormat,
  LIMIT,
  ROLE_KEYS,
  SORT_BY_LABEL,
  SORT_BY_NAME,
  STANDARD_DATE_FORMAT,
  STATES_LIST,
  TAX_CATEGORIES,
} from '../../../common/constants';
import LoaderComponent from '../../../components/LoaderComponent';
import MongoCommonSelect from '../../../components/MongoCommonSelect';
import useRouter from '../../../hooks/useRouter';
import { LIST_ASSESSEESS } from '../../assesses/graphql/queries';
import { LIST_JUDGES } from '../../judges/graphql/queries';
import KeywordsModal from '../../keywords/components/KeywordsModal';
import SectionsModal from '../../sections/components/SectionModal';
import { UPDATE_SYNC_APPEAL } from '../graphql/mutations';
import { GET_KEYWORD, GET_LAWYERS, GET_SECTIONS } from '../graphql/queries';
import AddJudgeModal from './AddJudgeModal';
import AssesseeDetails from './AssesseeDetails';

const { RangePicker } = DatePicker;

function JudgementForm({
  form,
  onFinish,
  loading,
  initialValues = null,
  loadingJudgementDetails = true,
  styling = { sm: 8, md: 8, lg: 8 },
  hideAddButtons = true,
  onAssesseeUpdate,
  handleDelete,
}) {
  const formContainerRef = useRef(null);
  const assesseStyling = { sm: 24, md: 24, lg: 24 };
  const keywordsValues = Form?.useWatch('keywords', form);
  const judgesValues = Form?.useWatch('judges', form);
  const assessValue = Form?.useWatch('assessee', form);
  const sectionValue = Form?.useWatch('sections', form);
  const assesseeLawyersValue = Form?.useWatch('assesseeLawyers', form);
  const departmentLawyers = Form?.useWatch('departmentLawyers', form);
  const {
    state: { currentUser },
  } = useContext(AppContext);
  const isStaff = useMemo(() => currentUser?.roles.includes(ROLE_KEYS.STAFF), [
    currentUser?.roles,
  ]);
  const { navigate } = useRouter();
  const { category } = useParams();
  const commonInitialFilter = {
    skip: 0,
    limit: LIMIT,
    search: '',
  };
  const [keywordFormPopup, setKeywordFormPopup] = useState({
    isOpen: false,
    id: 'new',
  });
  const [sectionFormPopup, setSectionFormPopup] = useState({
    isOpen: false,
    id: 'new',
  });
  const [openAddJudgeModal, setOpenAddJudgeModal] = useState(false);
  if (loadingJudgementDetails) return <LoaderComponent />;

  const handleKeywordClose = () => {
    setKeywordFormPopup({
      isOpen: false,
      id: null,
    });
  };

  const handleSectionClose = () => {
    setSectionFormPopup({
      isOpen: false,
      id: null,
    });
  };

  const handleAiFieldHandler = async (e) => {
    const boolValue = e.target.checked;
    const preparePayload = {
      where: {
        // eslint-disable-next-line no-underscore-dangle
        id: initialValues?._id,
      },
      data: {
        useAiData: boolValue,
      },
    };
    await mongoClient
      ?.mutate({
        mutation: UPDATE_SYNC_APPEAL,
        variables: preparePayload,
      })
      ?.then(() => {
        form.setFieldValue('useAiData', boolValue);
      })
      ?.catch(() => {});
  };

  const initialJudgementFormValues = {
    ...initialValues,
    addToNewsLetter: Boolean(initialValues?.addToNewsLetter),
    dateOfPronouncement: initialValues?.dateOfPronouncement
      ? dayjs(initialValues?.dateOfPronouncement)
      : null,
    aiPronouncementDate: initialValues?.aiPronouncementDate
      ? dayjs(initialValues?.aiPronouncementDate)
      : null,
    appealFiledOn:
      initialValues?.appealFiledOn && dayjs(initialValues?.appealFiledOn),
    dateOfFirstHearing:
      initialValues?.dateOfFirstHearing &&
      dayjs(initialValues?.dateOfFirstHearing),
    dateOfLastHearing:
      initialValues?.dateOfLastHearing &&
      dayjs(initialValues?.dateOfLastHearing),
    dateOfNextHearing:
      initialValues?.dateOfNextHearing &&
      dayjs(initialValues?.dateOfNextHearing),
    dateOfFinalHearing:
      initialValues?.dateOfFinalHearing &&
      dayjs(initialValues?.dateOfFinalHearing),
    dateOfTribunalOrder:
      initialValues?.dateOfTribunalOrder &&
      dayjs(initialValues?.dateOfTribunalOrder),
    dateOfOrder:
      initialValues?.dateOfOrder && dayjs(initialValues?.dateOfOrder),
    assessee: initialValues?.assessee
      ? {
          // eslint-disable-next-line no-underscore-dangle
          value: initialValues?.assessee?._id,
          label: initialValues?.assessee?.name,
        }
      : null,
    judges: initialValues?.judges?.map((item) => ({
      // eslint-disable-next-line no-underscore-dangle
      value: item?._id,
      label: item?.name,
    })),
    aiJudges: initialValues?.aiJudges?.map((item) => ({
      // eslint-disable-next-line no-underscore-dangle
      value: item?._id,
      label: item?.name,
    })),
    keywords: initialValues?.keywords?.map((item) => ({
      // eslint-disable-next-line no-underscore-dangle
      value: item?._id,
      label: item?.label,
    })),
    aiKeywords: initialValues?.aiKeywords?.map((item) => ({
      // eslint-disable-next-line no-underscore-dangle
      value: item?._id,
      label: item?.label,
    })),
    sections: initialValues?.sections?.map((item) => ({
      // eslint-disable-next-line no-underscore-dangle
      value: item?._id,
      label: item?.label,
    })),
    aiSections: initialValues?.aiSections?.map((item) => ({
      // eslint-disable-next-line no-underscore-dangle
      value: item?._id,
      label: item?.label,
    })),
    assesseeLawyers: initialValues?.assesseeLawyers?.map((item) => ({
      // eslint-disable-next-line no-underscore-dangle
      value: item?._id,
      label: item?.name,
    })),
    aiAssesseeLawyers: initialValues?.aiAssesseeLawyers?.map((item) => ({
      // eslint-disable-next-line no-underscore-dangle
      value: item?._id,
      label: item?.name,
    })),
    aiBench: initialValues?.aiBench?.map((item) => ({
      // eslint-disable-next-line no-underscore-dangle
      value: item?._id,
      label: item?.name,
    })),
    departmentLawyers: initialValues?.departmentLawyers?.map((item) => ({
      // eslint-disable-next-line no-underscore-dangle
      value: item?._id,
      label: item?.name,
    })),
    aiDepartmentLawyers: initialValues?.aiDepartmentLawyers?.map((item) => ({
      // eslint-disable-next-line no-underscore-dangle
      value: item?._id,
      label: item?.name,
    })),
    benchAllotted: initialValues?.benchAllotted ?? '',
    updatedAt: initialValues?.updatedAt
      ? dayjs(initialValues?.updatedAt)?.format(dateTimeFormat)
      : '',
    createdAt: initialValues?.createdAt
      ? dayjs(initialValues?.createdAt)?.format(dateTimeFormat)
      : '',
    issuePeriod: [
      initialValues?.issuePeriod?.[0]
        ? dayjs(initialValues?.issuePeriod?.[0])
        : null,
      initialValues?.issuePeriod?.[1]
        ? dayjs(initialValues?.issuePeriod?.[1])
        : null,
    ],
    aiIssuePeriods: [
      initialValues?.issuePeriod?.[0]
        ? dayjs(initialValues?.aiIssuePeriods?.[0])
        : null,
      initialValues?.issuePeriod?.[1]
        ? dayjs(initialValues?.aiIssuePeriods?.[1])
        : null,
    ],
  };

  const handleCancel = () => {
    form.setFieldsValue(initialJudgementFormValues);
    if (formContainerRef?.current) {
      formContainerRef?.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <>
      <KeywordsModal
        formPopup={keywordFormPopup}
        handleCloseModal={handleKeywordClose}
        handleModalForm={handleKeywordClose}
      />
      <SectionsModal
        formPopup={sectionFormPopup}
        handleCloseModal={handleSectionClose}
        handleModalForm={handleSectionClose}
      />
      <AddJudgeModal
        openAddJudgeModal={openAddJudgeModal}
        setOpenAddJudgeModal={setOpenAddJudgeModal}
      />

      <div className="card-body-wrapper" ref={formContainerRef}>
        <Form
          form={form}
          name="register"
          onFinish={onFinish}
          layout="vertical"
          initialValues={initialJudgementFormValues}
          disabled={hideAddButtons ? isStaff : false}
        >
          <Row gutter={16}>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item
                name="appealNumber"
                label="Appeal No."
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: 'Invalid Number',
                  },
                ]}
              >
                <Input placeholder="Enter Appeal No." />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item
                name="appealRSAnumber"
                label="Counselvise Citation"
                rules={[
                  {
                    whitespace: true,
                  },
                ]}
              >
                <Input placeholder="Enter Counselvise Citation" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item
                name="category"
                label="Category"
                className="user-type-input"
                rules={[
                  {
                    required: true,
                    message: 'Please select a Category',
                  },
                ]}
              >
                <Select placeholder="Select Type" disabled={hideAddButtons}>
                  {Object.entries(TAX_CATEGORIES).map(([value, label]) => (
                    <Select.Option key={value} value={value}>
                      {label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item
                name="appealType"
                label="Appeal Type"
                rules={[
                  {
                    whitespace: true,
                  },
                ]}
              >
                <Input disabled placeholder="Enter Appeal Type" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item name="bench" label="Bench" className="user-type-input">
                <Select placeholder="Select Bench" disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item
                label="Appeal Filed By"
                name="appealFiledBy"
                rules={[
                  {
                    whitespace: true,
                    message: 'Please enter who filed Appeal',
                  },
                ]}
              >
                <Input placeholder="Appeal Filed By" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item
                name="appellant"
                label="Appellant"
                rules={[
                  {
                    whitespace: true,
                    message: 'Please enter Appellant',
                  },
                ]}
              >
                <Input placeholder="Enter Appellant" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item name="court" label="Court">
                <Select placeholder="Select Court">
                  {Object.entries(COURT_LIST).map(([value, label]) => (
                    <Select.Option key={value} value={value}>
                      {label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item
                name="respondent"
                label="Respondent"
                rules={[
                  {
                    whitespace: true,
                    message: 'Please enter Respondent',
                  },
                ]}
              >
                <Input placeholder="Enter Respondent" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item
                name="detailsURL"
                label="Details URL"
                rules={[
                  {
                    whitespace: true,
                    message: 'Please enter Details URL',
                  },
                ]}
              >
                <Input placeholder="Enter Details URL" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item
                name="assessmentYear"
                label="Assessment Year"
                rules={[
                  {
                    whitespace: true,
                    message: 'Please enter Assessment Year',
                  },
                ]}
              >
                <Input placeholder="Enter Assessment Year" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item
                name="dateOfPronouncement"
                label="Date of Pronouncement"
              >
                <DatePicker format={STANDARD_DATE_FORMAT} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item
                name="dateOfFirstHearing"
                label="Date of First Hearing"
              >
                <DatePicker format={STANDARD_DATE_FORMAT} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item name="dateOfLastHearing" label="Date of Last Hearing">
                <DatePicker format={STANDARD_DATE_FORMAT} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item name="dateOfNextHearing" label="Date of Next Hearing">
                <DatePicker format={STANDARD_DATE_FORMAT} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item
                name="dateOfFinalHearing"
                label="Date of Final Hearing"
              >
                <DatePicker format={STANDARD_DATE_FORMAT} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item
                name="dateOfTribunalOrder"
                label="Date of Tribunal Hearing"
              >
                <DatePicker format={STANDARD_DATE_FORMAT} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item name="dateOfOrder" label="Date of Order">
                <DatePicker format={STANDARD_DATE_FORMAT} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item name="appealFiledOn" label="Appeal filed on">
                <DatePicker format={STANDARD_DATE_FORMAT} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item
                name="benchAllotted"
                label="Bench allotted"
                rules={[
                  {
                    whitespace: true,
                    message: 'Please enter Allotted Bench',
                  },
                ]}
              >
                <Input placeholder="Enter Allotted Bench" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item
                name="caseStatus"
                label="Case Status"
                rules={[
                  {
                    whitespace: true,
                    message: 'Please enter Case Status',
                  },
                ]}
              >
                <Input placeholder="Enter Case Status" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item
                name="orderResult"
                label="Order Result"
                rules={[
                  {
                    whitespace: true,
                    message: 'Please enter Order Result',
                  },
                ]}
              >
                <Input placeholder="Enter Order Result" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item name="pdfUrl" label="PDF URL">
                <Input disabled placeholder="Enter PDF URL" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item name="processPdfStatus" label="Process PDF Status">
                <Input disabled placeholder="Enter AI Process PDF Status" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item name="errorMessage" label="Error Message">
                <Input disabled placeholder="Error Message" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item name="tags" label="Tags">
                <Input placeholder="Enter Tags" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item
                name="assessee"
                label="Assessee"
                rules={[
                  {
                    required: true,
                    message: 'Please select Assessee',
                  },
                ]}
                className="user-type-input"
              >
                <MongoCommonSelect
                  getPopupContainer={(trigger) => trigger?.parentNode}
                  placeholder="Select Assessee"
                  className="role-select-in-calls ml-8"
                  showSearch
                  allowClear
                  query={LIST_ASSESSEESS}
                  fetchPolicy="network-only"
                  responsePath="data.assesseesAdmin.data"
                  valuePath="_id"
                  labelPath="name"
                  variables={{
                    filter: {
                      ...commonInitialFilter,
                      category: hideAddButtons
                        ? CATEGORIES_KEYS[category]
                        : null,
                    },
                    sort: SORT_BY_NAME,
                  }}
                  value={assessValue}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item
                name="judges"
                label={
                  <div className="judgement-label-text ">
                    <div>Judges</div>
                    {!hideAddButtons && (
                      <div
                        className="ml-16 judgement-tags"
                        onClick={() => setOpenAddJudgeModal(true)}
                      >
                        + Add Judges
                      </div>
                    )}
                  </div>
                }
                className="user-type-input judgment-label"
              >
                <MongoCommonSelect
                  getPopupContainer={(trigger) => trigger?.parentNode}
                  placeholder="Select Judges"
                  className="role-select-in-calls ml-8"
                  showSearch
                  allowClear
                  query={LIST_JUDGES}
                  fetchPolicy="network-only"
                  responsePath="data.judgesAdmin.data"
                  valuePath="_id"
                  labelPath="name"
                  variables={{
                    filter: {
                      ...commonInitialFilter,
                      category: hideAddButtons
                        ? CATEGORIES_KEYS[category]
                        : null,
                    },
                    sort: SORT_BY_NAME,
                  }}
                  value={judgesValues}
                  mode="multiple"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item
                name="keywords"
                label={
                  <div className="judgement-label-text ">
                    <div>Keywords</div>
                    {!hideAddButtons && (
                      <div
                        className="ml-16 judgement-tags"
                        onClick={() =>
                          setKeywordFormPopup({ isOpen: true, id: 'new' })
                        }
                      >
                        + Add Keywords
                      </div>
                    )}
                  </div>
                }
                className="user-type-input judgment-label"
              >
                <MongoCommonSelect
                  getPopupContainer={(trigger) => trigger?.parentNode}
                  placeholder="Select Keywords"
                  className="role-select-in-calls ml-8"
                  showSearch
                  allowClear
                  query={GET_KEYWORD}
                  fetchPolicy="network-only"
                  responsePath="data.keywordsAdmin.data"
                  valuePath="_id"
                  labelPath="label"
                  variables={{
                    filter: commonInitialFilter,
                    sort: SORT_BY_LABEL,
                  }}
                  value={keywordsValues}
                  mode="multiple"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item
                name="sections"
                label={
                  <div className="judgement-label-text ">
                    <div>Sections</div>
                    {!hideAddButtons && (
                      <div
                        className="ml-16 judgement-tags"
                        onClick={() =>
                          setSectionFormPopup({ isOpen: true, id: 'new' })
                        }
                      >
                        + Add Sections
                      </div>
                    )}
                  </div>
                }
                className="user-type-input judgment-label"
              >
                <MongoCommonSelect
                  getPopupContainer={(trigger) => trigger?.parentNode}
                  placeholder="Select Section"
                  className="role-select-in-calls ml-8"
                  showSearch
                  allowClear
                  query={GET_SECTIONS}
                  fetchPolicy="network-only"
                  responsePath="data.sectionsAdmin.data"
                  valuePath="_id"
                  labelPath="label"
                  variables={{
                    filter: commonInitialFilter,
                    sort: SORT_BY_LABEL,
                  }}
                  value={sectionValue}
                  mode="multiple"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item
                name="assesseeLawyers"
                label="Assessee Lawyers"
                className="user-type-input"
              >
                <MongoCommonSelect
                  getPopupContainer={(trigger) => trigger?.parentNode}
                  placeholder="Select Assessee Lawyers"
                  className="role-select-in-calls ml-8"
                  showSearch
                  allowClear
                  query={GET_LAWYERS}
                  fetchPolicy="network-only"
                  responsePath="data.lawyersAdmin.data"
                  valuePath="_id"
                  labelPath="name"
                  variables={{
                    filter: commonInitialFilter,
                    sort: SORT_BY_NAME,
                  }}
                  value={assesseeLawyersValue}
                  mode="multiple"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item
                name="departmentLawyers"
                label="Department Lawyers"
                className="user-type-input"
              >
                <MongoCommonSelect
                  getPopupContainer={(trigger) => trigger?.parentNode}
                  placeholder="Select Department Lawyers"
                  className="role-select-in-calls ml-8"
                  showSearch
                  allowClear
                  query={GET_LAWYERS}
                  fetchPolicy="network-only"
                  responsePath="data.lawyersAdmin.data"
                  valuePath="_id"
                  labelPath="name"
                  variables={{
                    filter: commonInitialFilter,
                    sort: { sortBy: 'DESC', sortOn: 'createdAt' },
                  }}
                  mode="multiple"
                  value={departmentLawyers}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item name="resultInFavourOf" label="Result In Favour Of">
                <Input placeholder="Result in Favour Of" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item
                name="yearOfPronouncement"
                label="Year Of Pronouncement"
              >
                <Input placeholder="Year of Pronouncement" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item name="durationOfJustice" label="Duration Of Justice">
                <Input placeholder="Duration of Justice" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item name="slug" label="Slug">
                <Input disabled placeholder="Slug" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item name="notes" label="Notes">
                <Input placeholder="Notes" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item name="downloadCounter" label="Download Counter">
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item name="createdAt" label="Created At">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item name="updatedAt" label="Updated At">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item name="region" label="Region">
                <Select
                  options={STATES_LIST}
                  placeholder="Select Region"
                  showSearch
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item name="issuePeriod" label="Issue Period">
                <RangePicker format={STANDARD_DATE_FORMAT} allowClear />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item
                name="judgmentText"
                label="Judgment Text"
                rules={[
                  {
                    whitespace: true,
                    message: 'Please enter Judgment Text',
                  },
                ]}
              >
                <TextArea
                  type="text"
                  placeholder="Enter Judgment Text"
                  rows={7}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item name="summary" label="AI Summary">
                <TextArea type="text" placeholder="Enter AI Summary" rows={5} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Row>
                <Col xs={24} sm={12} md={12} lg={12}>
                  <Form.Item name="addToNewsLetter" valuePropName="checked">
                    <Checkbox>Add to Newsletter</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            {/* AI fields starts */}
            {process.env.REACT_APP_AI_FIELDS === 'true' && (
              <>
                <Col xs={24} sm={24} md={24} lg={24} className="mb-32">
                  <Row>
                    <Col xs={24} sm={12} md={12} lg={12}>
                      <Form.Item name="useAiData" valuePropName="checked">
                        <Checkbox onChange={(e) => handleAiFieldHandler(e)}>
                          Use AI Fields
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
                  <Form.Item
                    name="aiJudges"
                    label={
                      <div className="judgement-label-text ">
                        <div> AI Judges</div>
                        {!hideAddButtons && (
                          <div
                            className="ml-16 judgement-tags"
                            onClick={() => setOpenAddJudgeModal(true)}
                          >
                            + Add Judges
                          </div>
                        )}
                      </div>
                    }
                    className="user-type-input judgment-label"
                  >
                    <MongoCommonSelect
                      getPopupContainer={(trigger) => trigger?.parentNode}
                      placeholder="Select AI Judges"
                      className="role-select-in-calls"
                      showSearch
                      allowClear
                      query={LIST_JUDGES}
                      fetchPolicy="network-only"
                      responsePath="data.judgesAdmin.data"
                      valuePath="_id"
                      labelPath="name"
                      variables={{
                        filter: {
                          ...commonInitialFilter,
                          category: hideAddButtons
                            ? CATEGORIES_KEYS[category]
                            : null,
                        },
                        sort: SORT_BY_NAME,
                      }}
                      value={judgesValues}
                      mode="multiple"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
                  <Form.Item
                    name="aiKeywords"
                    label={
                      <div className="judgement-label-text ">
                        <div>AI Keywords</div>
                        {!hideAddButtons && (
                          <div
                            className="ml-16 judgement-tags"
                            onClick={() =>
                              setKeywordFormPopup({ isOpen: true, id: 'new' })
                            }
                          >
                            + Add Keywords
                          </div>
                        )}
                      </div>
                    }
                    className="user-type-input judgment-label"
                  >
                    <MongoCommonSelect
                      getPopupContainer={(trigger) => trigger?.parentNode}
                      placeholder="Select AI Keywords"
                      className="role-select-in-calls"
                      showSearch
                      allowClear
                      query={GET_KEYWORD}
                      fetchPolicy="network-only"
                      responsePath="data.keywordsAdmin.data"
                      valuePath="_id"
                      labelPath="label"
                      variables={{
                        filter: commonInitialFilter,
                        sort: SORT_BY_LABEL,
                      }}
                      value={keywordsValues}
                      mode="multiple"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
                  <Form.Item
                    name="aiSections"
                    label={
                      <div className="judgement-label-text ">
                        <div>AI Sections</div>
                        {!hideAddButtons && (
                          <div
                            className="ml-16 judgement-tags"
                            onClick={() =>
                              setSectionFormPopup({ isOpen: true, id: 'new' })
                            }
                          >
                            + Add Sections
                          </div>
                        )}
                      </div>
                    }
                    className="user-type-input judgment-label"
                  >
                    <MongoCommonSelect
                      getPopupContainer={(trigger) => trigger?.parentNode}
                      placeholder="Select AI Section"
                      className="role-select-in-calls"
                      showSearch
                      allowClear
                      query={GET_SECTIONS}
                      fetchPolicy="network-only"
                      responsePath="data.sectionsAdmin.data"
                      valuePath="_id"
                      labelPath="label"
                      variables={{
                        filter: commonInitialFilter,
                        sort: SORT_BY_LABEL,
                      }}
                      value={sectionValue}
                      mode="multiple"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
                  <Form.Item
                    name="aiAssesseeLawyers"
                    label="AI Assessee Lawyers"
                    className="user-type-input"
                  >
                    <MongoCommonSelect
                      getPopupContainer={(trigger) => trigger?.parentNode}
                      placeholder="Select AI Assessee Lawyers"
                      className="role-select-in-calls"
                      showSearch
                      allowClear
                      query={GET_LAWYERS}
                      fetchPolicy="network-only"
                      responsePath="data.lawyersAdmin.data"
                      valuePath="_id"
                      labelPath="name"
                      variables={{
                        filter: commonInitialFilter,
                        sort: SORT_BY_NAME,
                      }}
                      value={assesseeLawyersValue}
                      mode="multiple"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
                  <Form.Item
                    name="aiDepartmentLawyers"
                    label="AI Department Lawyers"
                    className="user-type-input"
                  >
                    <MongoCommonSelect
                      getPopupContainer={(trigger) => trigger?.parentNode}
                      placeholder="Select AI Department Lawyers"
                      className="role-select-in-calls"
                      showSearch
                      allowClear
                      query={GET_LAWYERS}
                      fetchPolicy="network-only"
                      responsePath="data.lawyersAdmin.data"
                      valuePath="_id"
                      labelPath="name"
                      variables={{
                        filter: commonInitialFilter,
                        sort: { sortBy: 'DESC', sortOn: 'createdAt' },
                      }}
                      mode="multiple"
                      value={departmentLawyers}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
                  <Form.Item
                    name="aiBench"
                    label="AI Bench"
                    className="user-type-input"
                  >
                    <Select placeholder="Select AI Bench" disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
                  <Form.Item name="aiIssuePeriods" label="AI Issue Period">
                    <RangePicker format={STANDARD_DATE_FORMAT} allowClear />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
                  <Form.Item
                    name="aiPdfProcessStatus"
                    label="AI Process PDF Status"
                  >
                    <Input disabled placeholder="Enter Process PDF Status" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
                  <Form.Item name="aiBatchId" label="AI Batch Id">
                    <Input disabled placeholder="Enter AI Batch Id" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
                  <Form.Item name="aiAppealNumber" label="AI Appeal No.">
                    <Input placeholder="Enter AI Appeal No." />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
                  <Form.Item name="aiCourt" label="AI Court">
                    <Select placeholder="Select AI Court">
                      {Object.entries(COURT_LIST).map(([value, label]) => (
                        <Select.Option key={value} value={value}>
                          {label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
                  <Form.Item
                    name="aiAppellant"
                    label="AI Appellant"
                    rules={[
                      {
                        whitespace: true,
                        message: 'Please enter AI Appellant',
                      },
                    ]}
                  >
                    <Input placeholder="Enter AI Appellant" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
                  <Form.Item
                    name="aiRespondent"
                    label="AI Respondent"
                    rules={[
                      {
                        whitespace: true,
                        message: 'Please enter AI Respondent',
                      },
                    ]}
                  >
                    <Input placeholder="Enter AI Respondent" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
                  <Form.Item
                    name="aiPronouncementDate"
                    label="Date of AI Pronouncement"
                  >
                    <DatePicker format={STANDARD_DATE_FORMAT} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
                  <Form.Item
                    name="aiResultInFavourOf"
                    label="AI Result In Favour Of"
                  >
                    <Input placeholder="Enter AI Result In Favour Of" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
                  <Form.Item
                    name="aiTribunalOrderUrl"
                    label="AI Tribunal Order Url"
                  >
                    <Input placeholder="Enter AI Tribunal Order Url" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
                  <Form.Item
                    name="aiYearOfPronouncement"
                    label="AI Year Of Pronouncement"
                  >
                    <Input placeholder="Enter AI Year of Pronouncement" />
                  </Form.Item>
                </Col>
              </>
            )}
            {/* code commented for future use */}
            {/* <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item
                className="mt-24"
                name="isAiGenerative"
                valuePropName="checked"
              >
                <Checkbox>Ai Generative</Checkbox>
              </Form.Item>
            </Col>
            <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
              <Form.Item name="isRsaGenerated" valuePropName="checked">
                <Checkbox>RSA Generated</Checkbox>
              </Form.Item>
            </Col> */}
            {/* AI fields ends */}
          </Row>
          <Form.Item>
            <div className="d-flex align-center justify-between">
              <Space size={8}>
                <Button
                  onClick={() =>
                    hideAddButtons ? navigate(-1) : handleCancel()
                  }
                >
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Save
                </Button>
              </Space>
              {!hideAddButtons && (
                <Button danger onClick={() => handleDelete?.()}>
                  Delete
                </Button>
              )}
            </div>
          </Form.Item>
        </Form>
        {!loading && !hideAddButtons && (
          <div>
            <div className="review-appeal-content-assessee">
              <div className="review-appeal-assessee-title">
                Assessee Details
              </div>
              <div>
                <AssesseeDetails
                  initialValues={initialValues}
                  styling={assesseStyling}
                  loadingJudgementDetails={loading}
                  onAssesseeUpdate={onAssesseeUpdate}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default JudgementForm;
