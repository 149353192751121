import React, { useContext, useEffect, useState } from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Space,
  Switch,
} from 'antd';
import { City, State } from 'country-state-city';
import { includes } from 'lodash';
import { AppContext } from '../../AppContext';
import {
  LIMIT,
  ROLES_SELECTION_FOR_STAFF,
  ROLES_SELECTION_FOR_SUPER_ADMIN_AND_ADMIN,
  ROLE_KEYS,
  ROUTES,
  USER_TYPE,
} from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import LoaderComponent from '../../components/LoaderComponent';
import MongoCommonSelect from '../../components/MongoCommonSelect';
import useRouter from '../../hooks/useRouter';
import { GET_PAYMENT_DISTRIBUTION_FOR_USER } from '../payment-distribution/graphql/queries';
import { LIST_BENCH } from '../review-appeals/graphql/queries';
import PaymentDistributionForm from './components/PaymentDistributionForm';
import { CREATE_USER, UPDATE_USER } from './graphql/mutations';
import { FETCH_USER_BY_ID_QUERY } from './graphql/queries';

const defaultFormValue = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  state: '',
  city: '',
  companyName: '',
  subscribeToNewsLettersAt: false,
  roles: '',
  profession: '',
  isActive: true,
};
const {
  required,
  email,
  mobileNumber,
  name: validateName,
} = formValidatorRules;

function EditUser() {
  const { getCurrentUserRole } = useContext(AppContext);
  const roles = getCurrentUserRole() || {};
  const { params, navigate } = useRouter();
  const { id } = params;
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitLoader, setIsSubmitLoader] = useState(false);
  const [isFieldsChanged, setIsFieldsChanged] = useState(false);
  const [stateCode, setStateCode] = useState('');
  const [selectedBenches, setSelectedBenches] = useState([]);
  const [selectedBenchesIds, setSelectedBenchesIds] = useState([]);
  const [fetchUserDataCall, { data }] = useLazyQuery(FETCH_USER_BY_ID_QUERY, {
    fetchPolicy: 'network-only',
  });
  const [
    getPaymentDistribution,
    { data: { paymentDistributionAdmin } = {}, loading },
  ] = useLazyQuery(GET_PAYMENT_DISTRIBUTION_FOR_USER, {
    fetchPolicy: 'network-only',
    onError() {},
  });

  const stateOption = State.getStatesOfCountry('IN').map((state) => ({
    value: state.name,
    label: state.name,
    stateCode: state?.isoCode,
  }));

  useEffect(() => {
    if (id) {
      fetchUserDataCall({
        fetchPolicy: 'network-only',
        variables: { where: { id } },
        onCompleted(res) {
          const getUserData = { ...res?.user };
          getUserData.roles = getUserData?.roles?.[0];
          const list = res?.user?.userBenches?.map((e) => ({
            key: e.benchKey,
            value: e.benchId,
            label: e.benchSlug,
          }));
          getUserData.subscribeToNewsLettersAt = Boolean(
            getUserData?.subscribeToNewsLettersAt,
          );
          setSelectedBenches(list);
          getUserData.benches = list;
          setSelectedBenchesIds(list?.map((e) => e.value));
          form?.setFieldsValue(getUserData);
          setIsLoading(false);
        },
        onError() {},
      });
      getPaymentDistribution({
        variables: {
          where: {
            id,
          },
        },
      });
    } else {
      setIsLoading(false);
    }
  }, [id]);

  const [createUserCall] = useMutation(CREATE_USER);
  const [updateUserCall] = useMutation(UPDATE_USER);

  const onFinish = (values) => {
    setIsSubmitLoader(true);
    const { benches, ...newObject } = values;
    const prepareValues = {
      ...newObject,
      userBenches: selectedBenches?.map((e) => ({
        benchId: e.value,
        benchKey: e.key,
        benchSlug: e.label,
        benchValue: e.label,
      })),
    };

    prepareValues.roles = [prepareValues?.roles];
    if (id) {
      updateUserCall({
        variables: {
          userWhere: { id },
          data: prepareValues,
        },
        onCompleted() {
          navigate(ROUTES.USERS);
        },
        onError() {},
      });
    } else {
      createUserCall({
        variables: { data: prepareValues },
        onCompleted() {
          navigate(ROUTES.USERS);
        },
        onError() {},
      });
    }
    setSelectedBenches([]);
    setIsSubmitLoader(false);
  };

  const getRoleSelection = (role) => {
    switch (role) {
      case USER_TYPE.STAFF:
        return ROLES_SELECTION_FOR_STAFF;
      default:
        return ROLES_SELECTION_FOR_SUPER_ADMIN_AND_ADMIN;
    }
  };

  const handleChange = (e, value) => {
    form.setFieldValue('city', null);
    if (!Array.isArray(value)) {
      setStateCode(value.stateCode);
    }
  };

  const allowedRolesForTransaction = [
    ROLE_KEYS.SUPER_ADMIN,
    ROLE_KEYS.ADMIN,
    ROLE_KEYS.STAFF,
    ROLE_KEYS.PREMIUM_CONSULTANT,
  ];

  const applyTransactionFilter = () => {
    navigate({
      pathname: ROUTES.TRANSACTION_LOGS,
      search: `providerId=${data?.user?.id}&providerName=${data?.user?.firstName} ${data?.user?.lastName}`,
    });
  };

  if (isLoading) return <LoaderComponent />;

  return (
    <>
      <div className="title">
        <Button
          type="text"
          shape="circle"
          onClick={() => navigate(ROUTES.USERS)}
          icon={<ArrowLeftOutlined />}
        />
        {id ? 'Edit User' : 'Add User'}
      </div>
      <Card className="ant-body-scroll">
        <div className="card-body-wrapper">
          <div className="d-flex justify-between align-center">
            <h3>User Details</h3>
            {includes(allowedRolesForTransaction, data?.user?.roles?.[0]) && (
              <Button type="primary" onClick={applyTransactionFilter}>
                Transaction logs
              </Button>
            )}
          </div>
          <Form
            form={form}
            name="register"
            onFinish={onFinish}
            onValuesChange={() => setIsFieldsChanged(true)}
            initialValues={defaultFormValue}
            layout="vertical"
            disabled={
              data?.user?.migratedFrom === 'ITAT' &&
              data?.user?.phoneNumberVerifiedAt === null
            }
          >
            <Row gutter={16}>
              <Col xs={24} sm={12} md={8} lg={8}>
                <Form.Item
                  name="roles"
                  label="Role"
                  className="user-type-input"
                  rules={[{ required, message: 'Please select User Role' }]}
                >
                  <Select placeholder="Select Role">
                    {getRoleSelection(roles)?.map((role) => (
                      <Select.Option key={role?.value} value={role?.value}>
                        {role?.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={12} md={8}>
                <Form.Item
                  name="firstName"
                  label="First name"
                  rules={[
                    { required, message: 'Please enter First Name' },
                    validateName,
                  ]}
                >
                  <Input placeholder="Enter your First Name" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item
                  name="lastName"
                  label="Last name"
                  rules={[
                    { required, message: 'Please enter Last Name' },
                    validateName,
                  ]}
                >
                  <Input type="text" placeholder="Enter your Last Name" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[{ required, message: 'Please enter Email' }, email]}
                >
                  <Input
                    status={`${data?.tempEmail && 'warning'}`}
                    type="text"
                    placeholder="Email"
                  />
                </Form.Item>
              </Col>
              {data?.user?.tempEmail && (
                <Col xs={24} sm={12} md={8} lg={8}>
                  <Form.Item label="New Email">
                    <Input
                      disabled
                      value={data?.user?.tempEmail}
                      type="text"
                      placeholder="Please enter New Email"
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={12} md={8} lg={8}>
                <Form.Item
                  name="phoneNumber"
                  label="Phone Number"
                  rules={[
                    { required, message: 'Please enter Phone Number' },
                    mobileNumber,
                  ]}
                >
                  <Input type="number" placeholder="Enter your Phone Number" />
                </Form.Item>
              </Col>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.roles !== currentValues.roles
                }
              >
                {({ getFieldValue }) =>
                  [ROLE_KEYS.STAFF].includes(getFieldValue('roles')) && (
                    <Col xs={24} sm={12} md={8} lg={8}>
                      <Form.Item name="benches" label="Benches">
                        <MongoCommonSelect
                          getPopupContainer={(trigger) => trigger?.parentNode}
                          placeholder="Select Benches"
                          className="role-select-in-calls ml-8"
                          showSearch
                          allowClear
                          query={LIST_BENCH}
                          fetchPolicy="network-only"
                          responsePath="data.benchesAdmin.data"
                          valuePath="_id"
                          labelPath="value"
                          variables={{
                            filter: {
                              skip: 0,
                              limit: LIMIT,
                              search: '',
                            },
                            sort: { sortBy: 'DESC', sortOn: 'createdAt' },
                          }}
                          mode="multiple"
                          onSelect={(val, record) => {
                            const list = [...selectedBenches, record];
                            setSelectedBenches(list);
                            form.setFieldValue('benches', list);
                          }}
                          onDeselect={(val) => {
                            const list = selectedBenches?.filter(
                              (e) => e.value !== val,
                            );
                            setSelectedBenches(list);
                            form.setFieldValue('benches', list);
                          }}
                          onClear={() => setSelectedBenches([])}
                          value={selectedBenchesIds}
                        />
                      </Form.Item>
                    </Col>
                  )
                }
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.roles !== currentValues.roles
                }
              >
                {({ getFieldValue }) =>
                  [
                    ROLE_KEYS.ADVISER,
                    ROLE_KEYS.ADVICE_SEEKER,
                    ROLE_KEYS.PREMIUM_CONSULTANT,
                  ].includes(getFieldValue('roles')) && (
                    <>
                      <Col xs={24} sm={12} md={8} lg={8}>
                        <Form.Item
                          name="state"
                          label="State"
                          rules={[required, { message: 'Please select State' }]}
                        >
                          <Select
                            className="state-input"
                            placeholder="State"
                            showSearch
                            options={stateOption}
                            onChange={handleChange}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={8} lg={8}>
                        <Form.Item
                          name="city"
                          label="City"
                          className="form-label"
                          rules={[required, { message: 'Please select City' }]}
                        >
                          <Select
                            disabled={stateCode === ''}
                            className="state-input"
                            placeholder="City"
                            showSearch
                            options={City.getCitiesOfState('IN', stateCode).map(
                              (city) => ({
                                value: city.name,
                                label: city.name,
                              }),
                            )}
                          />
                        </Form.Item>
                      </Col>
                    </>
                  )
                }
              </Form.Item>
            </Row>
            <Row gutter={16}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.roles !== currentValues.roles
                }
              >
                {({ getFieldValue }) =>
                  [ROLE_KEYS.ADVISER, ROLE_KEYS.ADVICE_SEEKER].includes(
                    getFieldValue('roles'),
                  ) && (
                    <Col xs={24} sm={12} md={8} lg={8}>
                      <Form.Item name="companyName" label="Company Name">
                        <Input
                          className="form-input"
                          type="text"
                          placeholder="Company Name"
                        />
                      </Form.Item>
                    </Col>
                  )
                }
              </Form.Item>
              <Col xs={24} sm={12} md={8} lg={8}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.roles !== currentValues.roles
                  }
                >
                  {({ getFieldValue }) =>
                    getFieldValue('roles') === ROLE_KEYS.ADVISER && (
                      <Form.Item
                        name="profession"
                        label="Profession"
                        rules={[
                          required,
                          { message: 'Please enter Profession' },
                        ]}
                      >
                        <Input
                          className="form-input"
                          type="text"
                          placeholder="Profession"
                        />
                      </Form.Item>
                    )
                  }
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={12} md={8} lg={8}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.roles !== currentValues.roles
                  }
                >
                  {({ getFieldValue }) =>
                    [ROLE_KEYS.ADVISER, ROLE_KEYS.ADVICE_SEEKER].includes(
                      getFieldValue('roles'),
                    ) && (
                      <Form.Item
                        name="subscribeToNewsLettersAt"
                        valuePropName="checked"
                      >
                        <Checkbox className="d-flex align-center ">
                          Subscribe to Newsletter
                        </Checkbox>
                      </Form.Item>
                    )
                  }
                </Form.Item>
              </Col>
            </Row>
            {id && (
              <Row gutter={16}>
                <Col xs={24} sm={12} md={8} lg={8}>
                  <Form.Item
                    name="isActive"
                    label="Is Active"
                    className="user-type-input"
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                </Col>
              </Row>
            )}
            <Col span={24}>
              <Form.Item>
                <Space size={8}>
                  <Button
                    htmlType="submit"
                    onClick={() => navigate(ROUTES.USERS)}
                    loading={isSubmitLoader}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={isSubmitLoader}
                    disabled={!isFieldsChanged}
                    onClick={() => form.handleSubmit}
                  >
                    Save
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Form>
          {id &&
            data?.user?.phoneNumberVerifiedAt &&
            data?.user?.roles?.includes(USER_TYPE.PREMIUM_CONSULTANT) && (
              <>
                <Divider />
                <h3>Payment distribution</h3>
                <LoaderComponent spinning={loading}>
                  <PaymentDistributionForm data={paymentDistributionAdmin} />
                </LoaderComponent>
              </>
            )}
        </div>
      </Card>
    </>
  );
}

export default EditUser;
