import { State } from 'country-state-city';

/* ROUTERS  */
export const ROUTES = {
  MAIN: '/',
  LOGOUT: '/logout',
  SIGNUP: '/signup',
  LOGIN: '/login',
  DASHBOARD: '/dashboard',
  SET_PASSWORD: '/set-password',
  RESET_PASSWORD: '/reset-password',
  FORGET_PASSWORD: '/forgot-password',
  VERIFY_EMAIL: '/verify-email',
  RESET: '/reset-password',
  VERIFY: '/verify',
  AUTHENTICATION: '/authentication',
  USERS: '/user',
  TEMPLATE_CATEGORY: '/template-category',
  TEMPLATE: '/template',
  ADD_TEMPLATE: '/template/add-template',
  EDIT_TEMPLATE: '/template/edit/:id',
  USER_DETAIL: '/user/:id',
  ADD_USERS: '/user/add-user',
  EDIT_USER: '/user/edit/:id',
  PROFILE: '/profile',
  ACCESS_DENIED: '/access-denied',
  SUBSCRIPTION_PLANS: '/subscription-plans',
  JUDGES: '/judges',
  ADD_JUDGE: '/judges/create',
  EDIT_JUDGE: '/judges/edit/',
  ASSESSES: '/assesses',
  ADD_ASSESSES: '/assesses/create',
  EDIT_ASSESSES: '/assesses/edit',
  PAYMENT_DISTRIBUTION: '/payment-distribution',
  CREATE: '/create',
  EDIT: '/edit/:id',
  JUDGEMENTS: '/judgements',
  DIRECT_TAX: '/direct-tax',
  INDIRECT_TAX: '/indirect-tax',
  CORPORATE_LAW: 'corporate-law',
  COMPANY_LAW: '/corporate-law',
  ADD_JUDGEMENTS: '/judgements/create',
  EDIT_JUDGEMENTS: '/judgements/edit/',
  CONSULTATION_CALLS: '/consultation-calls',
  TRANSACTION_LOGS: '/transaction-logs',
  ADD_TRANSACTION: '/transaction-logs/create',
  EDIT_TRANSACTION: '/transaction-logs/edit',
  QUOTATIONS: '/quotations',
  SECTIONS: '/sections',
  KEYWORDS: '/keywords',
  LAWYERS: '/lawyers',
  REPORTS: '/reports',
  IMPORT_JUDGEMENT: '/import-judgement',
  REVIEW_APPEALS: '/review-appeals',
  IMPORT_CSV: '/import-csv',
  PAYMENTS: '/payments',
  ADD_PAYMENT: '/payments/create',
  EDIT_PAYMENT: '/payments/edit/:id',
  ACTS: '/acts',
  CREATE_ACTS: '/acts/create',
  EDIT_ACTS: '/acts/edit',
  NOTIFICATIONS: '/notifications',
  CREATE_NOTIFICATIONS: '/notifications/create',
  EDIT_NOTIFICATIONS: '/notifications/edit',
  CIRCULARS: '/circulars',
  CREATE_CIRCULARS: '/circulars/create',
  EDIT_CIRCULARS: '/circulars/edit',
  SUBMISSIONS: '/submissions',
  CREATE_SUBMISSIONS: '/submissions/create',
  EDIT_SUBMISSIONS: '/submissions/edit',
  ARGUMENTS: '/arguments',
  CREATE_ARGUMENTS: '/arguments/create',
  EDIT_ARGUMENTS: '/arguments/edit',
  CREATE_CSV: '/import-csv/create',
};

/*  Modules */
export const MODULES = {
  DASHBOARD: 'Dashboard',
  USER: 'Users',
  TEMPLATE_CATEGORY: 'Template Categories',
  TEMPLATE: 'Templates',
  SUBSCRIPTION_PLANS: 'Subscription Plans',
  JUDGES: 'Judges',
  ASSESSES: 'Assessees',
  PAYMENT_DISTRIBUTION: 'Payment Distribution',
  JUDGEMENTS: 'Judgements',
  CONSULTATION_CALLS: 'Consultation Calls',
  TRANSACTION_LOGS: 'Transaction Logs',
  QUOTATIONS: 'Quotations',
  SECTIONS: 'Sections',
  KEYWORDS: 'Keywords',
  LAWYERS: 'Lawyers',
  REPORTS: 'Reports',
  REVIEW_APPEALS: 'Review Appeals',
  PAYMENTS: 'Payments',
  JUDGEMENT_AI: 'Judgement Ai',
  DIRECT_TAX: 'Direct Tax',
  INDIRECT_TAX: 'Indirect Tax',
  CORPORATE_LAW: 'Corporate Law',
  ACTS: 'Acts',
  NOTIFICATIONS: 'Notifications/Circulars',
  CIRCULARS: 'Circulars',
  SUBMISSIONS: 'Submissions/Arguments',
  ARGUMENTS: 'Arguments',
  IMPORT_CSV: 'Import CSV',
};

/* Authentication */
export const TOKEN = 'TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const USER = 'USER';

export const ROLES = {
  SUPER_ADMIN: 'Super admin',
  ADMIN: 'Admin',
};

export const ROLE_KEYS = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
  ADVISER: 'ADVISER',
  ADVICE_SEEKER: 'ADVICE_SEEKER',
  STAFF: 'STAFF',
  PREMIUM_CONSULTANT: 'PREMIUM_CONSULTANT',
};

export const STATUS_TAG_COLOR = {
  PUBLISHED: 'success',
  DRAFT: 'default',
};

export const SORT = {
  ASC: 'ascend',
  DESC: 'descend',
};

export const SORT_OPTION = {
  ASC: 'ASC',
  DESC: 'DESC',
};

/* Date and time */
export const defaultDateFormat = 'MM/DD/YYYY';
export const dateTimeFormat = 'MMM DD YYYY, hh:mm a';
export const transactionDate = 'Do MMM, YYYY';
export const TIME_FORMAT = 'hh:mma';
export const STANDARD_DATE_FORMAT = 'DD/MM/YYYY';
export const IND_DATE_FORMAT = 'YYYY-MM-DD';

export const REGEX = {
  NAME: /^[a-z0-9 ,.'-]+$/i,
  ZIPCODE: /^[0-9]{6}$/,
  CITY: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  WEB_URL: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
  // eslint-disable-next-line no-useless-escape
  PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d])[A-Za-z\d!@#$%^&*()_+\-=\[\]{};:"\\|,.<>\/?~]{8,64}$/,
  PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
  MOBILE_NUMBER: /^[6789]\d{9}$/,
  EMAIL: /^(([^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+(\.[^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  AMOUNT: /^\d+$|^\d+\.\d*$/,
  OPTIONAL_NEGATIVE_AMOUNT: /^[-]?\d+$|^[-]?\d+\.\d*$/,
  NUMBER: /^\d+$/,
  COUNTRY_CODE_PHONE: /^[0-9]{7,12}$/,
  PANCARD: /^[A-Z]{5}[0-9]{4}[A-Z]$/i,
};

export const LIMIT = 10;
export const SCROLL_PERCENT = 0.85;
export const MAX_PRICE_LIMIT = 50000;
export const PAGE_SIZE_OPTIONS = [10, 20, 50];

export const GENDER = [
  { name: 'Female', value: 'FEMALE' },
  { name: 'Male', value: 'MALE' },
  { name: 'Other', value: 'OTHER' },
];
export const ERROR_PAGE_TITLE = 'Oops! An error occurred!';
export const ERROR_PAGE_SUBTITLE =
  'Something is broken. Please let us know what you were doing when this error occurred. We will fix it as soon as possible. Sorry for any inconvenience caused.';

export const GUTTER_VARIATIONS = { xs: 16, sm: 16, md: 24, lg: 32 };

export const TEMPLATE_STATUS_COLOR = {
  DRAFT: '#faad14',
  PUBLISHED: '#52c41a',
  UNPUBLISHED: '#FA541C',
};

export const TEMPLATE_TYPE = {
  FREE: 'FREE',
  PAID: 'PAID',
  PREMIUM: 'PREMIUM',
};

export const USER_TYPE = {
  ADVISER: 'ADVISER',
  STAFF: 'STAFF',
  ADMIN: 'ADMIN',
  ADVICE_SEEKER: 'ADVICE_SEEKER',
  SUPER_ADMIN: 'SUPER_ADMIN',
  PREMIUM_CONSULTANT: 'PREMIUM_CONSULTANT',
};

export const USER_TYPE_LABEL = {
  ADVISER: 'Advisor',
  STAFF: 'Staff',
  ADMIN: 'Admin',
  ADVICE_SEEKER: 'Advice seeker',
  SUPER_ADMIN: 'Super admin',
  PREMIUM_CONSULTANT: 'Premium consultant',
};

export const ROLES_SELECTION_FOR_STAFF = [
  { value: 'PREMIUM_CONSULTANT', label: 'Premium consultant' },
  { value: 'ADVISER', label: 'Advisor' },
  { value: 'ADVICE_SEEKER', label: 'Advice seeker' },
];

export const ROLES_SELECTION_FOR_SUPER_ADMIN_AND_ADMIN = [
  { value: 'ADMIN', label: 'Admin' },
  { value: 'STAFF', label: 'Staff' },
  { value: 'PREMIUM_CONSULTANT', label: 'Premium consultant' },
  { value: 'ADVISER', label: 'Advisor' },
  { value: 'ADVICE_SEEKER', label: 'Advice seeker' },
];

export const BREAKPOINTS = {
  mobile: 550,
  tablet: 767,
  desktop: 1000,
  hd: 1200,
};

export const TAX_CATEGORIES = {
  DIRECT_TAX: 'Direct Tax',
  INDIRECT_TAX: 'Indirect Tax',
  COMPANY_LAW: 'Company Law',
};

export const COURT_LIST = {
  HIGH_COURT: 'High Court',
  SUPREME_COURT: 'Supreme Court',
  TRIBUNAL: 'Tribunal Court',
};

export const JUDGES_TYPE = {
  JUDICIAL_MEMBER: 'Judicial Member',
  ACCOUNTANT_MEMBER: 'Accountant Member',
};

export const IMPORT_CSV_STATUS = {
  COMPLETED: 'Completed',
  PENDING: 'Pending',
  FAILED: 'Failed',
  IN_PROCESS: 'Processing',
};

export const IMPORT_CSV_VISIBILITY = {
  DRAFT: 'Draft',
  PUBLISHED: 'Published',
  PROCESSING: 'Processing',
};

export const PAYMENT_DISTRIBUTION_KEYS = {
  TYPE: 'Type',
  SUBSCRIPTION_BASED_COMMISSION_IN_PERCENT:
    'Subscription based commission in percent',
  SERVICE_CHARGES_IN_PERCENT: 'Service charges in percent',
  REFERRAL_CHARGES_IN_PERCENT: 'Referral charges in percent',
  PAID_PREMIUM_BASED_COMMISSION_IN_PERCENT:
    'Paid Premium based commission in percent',
  CONSULTATION_CALL_VALUE_IN_PERCENT: 'Consultation call value in percent',
  CONSULTATION_CALL_TYPE: 'Consultation call type',
};

export const CONSULTATION_CALL_TYPE = {
  FIXED: { key: 'FIXED', label: 'Fixed' },
  PERCENT: { key: 'PERCENT', label: 'Percent' },
};

export const CONSULTATION_CALL_FILTER = [
  { value: 'ONE_TO_ONE', label: '1-To-1' },
  { value: 'WEBINAR', label: 'Webinar' },
];

export const CONSULTATION_CALL_CATEGORY_FILTER = [
  { value: 'PERSONAL', label: 'Personal' },
  { value: 'PROFESSIONAL', label: 'Professional' },
];

export const CONSULTATION_CALL_FEATURED_FILTER = [
  { value: true, label: 'True' },
  { value: false, label: 'False' },
];

export const MEETING_TYPE_FILTER = [
  { value: 'VIRTUAL', label: 'Virtual' },
  { value: 'PHYSICAL', label: 'Physical' },
];

export const CALL_TYPE = {
  ONE_TO_ONE: 'ONE_TO_ONE',
  WEBINAR: 'WEBINAR',
};

export const TRANSACTION_LOGS_FILTER = [
  { value: 'TEMPLATE', label: 'Template' },
  { value: 'SUBSCRIPTION', label: 'Subscription' },
  { value: 'CALL', label: 'Consultation Call' },
  // { value: 'SERVICE', label: 'Services' },
  // { value: 'REFERRING_SERVICE', label: 'Referring Service' },
];

export const TRANSACTION_TYPE = {
  TEMPLATE: 'TEMPLATE',
  SUBSCRIPTION: 'SUBSCRIPTION',
  CALL: 'CALL',
  // SERVICE: 'SERVICE',
  // REFERRING_SERVICE: 'REFERRING_SERVICE',
};

export const TRANSACTION_STATUS_COLOR = {
  SETTLED: 'success',
  PENDING: 'processing',
  RESOLVED: 'magenta',
  IN_DISPUTE: 'volcano',
  CANCELED: 'error',
};

export const REPORT_STATUS_COLOR = {
  COMPLETED: 'success',
  PENDING: 'processing',
  FAILED: 'error',
};

export const TRANSACTION_STATUS_LABELS = {
  SETTLED: 'Settled',
  PENDING: 'Pending',
  RESOLVED: 'Resolved',
  IN_DISPUTE: 'In-Dispute',
  CANCELED: 'Canceled',
};

export const TRANSACTION_STATUS_SELECTION = [
  { value: 'SETTLED', label: 'Settled' },
  { value: 'PENDING', label: 'Pending' },
  { value: 'RESOLVED', label: 'Resolved' },
  { value: 'IN_DISPUTE', label: 'In-Dispute' },
  { value: 'CANCELED', label: 'Canceled' },
];

export const TEMPLATE_STATUS = {
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
  REJECTED: 'REJECTED',
  UNPUBLISHED: 'UNPUBLISHED',
};

export const STATUS_SELECTION = [
  { value: 'DRAFT', label: 'Draft' },
  { value: 'PUBLISHED', label: 'Publish' },
  { value: 'REJECTED', label: 'Reject' },
  { value: 'UNPUBLISHED', label: 'Unpublish' },
];

export const BOOKED_FROM_SELECTION = {
  WEB: { label: 'Counselvise', value: 'WEB' },
  MICROSITE: { label: 'Microsite', value: 'MICROSITE' },
};

export const PAYMENT_TYPE = {
  DEFAULT: { key: 'DEFAULT', label: 'Default' },
  SPECIFIC: { key: 'SPECIFIC', label: 'Specific' },
};

export const DYNAMIC_TAGS_LENGTH = 20;
export const PAGE_TYPE = {
  TEMPLATE: 'TEMPLATE',
  PLAN: 'SUBSCRIPTION_PLAN',
  CONSULTATION_CALL: 'CONSULTATION_CALL',
};

export const SHOW_ON_PAGE_OPTIONS = [
  {
    label: 'Direct tax',
    value: 'DIRECT_TAX',
  },
  {
    label: 'Indirect tax',
    value: 'INDIRECT_TAX',
  },
  {
    label: 'Corporate law',
    value: 'CORPORATE_LAW',
  },
];

export const CATEGORY_OPTIONS = [
  {
    label: 'Direct tax',
    value: 'DIRECT_TAX',
  },
  {
    label: 'Indirect tax',
    value: 'INDIRECT_TAX',
  },
  {
    label: 'Corporate law',
    value: 'COMPANY_LAW',
  },
];

export const EXPERT_SUMMARY_STATUS_SELECTION = [
  { value: 'DRAFT', label: 'Draft' },
  { value: 'PUBLISHED', label: 'Publish' },
];

export const PLAN_STATUS = { PUBLISHED: 'PUBLISHED' };

export const USER_ROLES_FILTERS = [
  {
    value: USER_TYPE.ADVISER,
    label: USER_TYPE_LABEL.ADVISER,
  },
  {
    value: USER_TYPE.ADVICE_SEEKER,
    label: USER_TYPE_LABEL.ADVICE_SEEKER,
  },
  {
    value: USER_TYPE.ADMIN,
    label: USER_TYPE_LABEL.ADMIN,
  },
  {
    value: USER_TYPE.STAFF,
    label: USER_TYPE_LABEL.STAFF,
  },
  {
    value: USER_TYPE.PREMIUM_CONSULTANT,
    label: USER_TYPE_LABEL.PREMIUM_CONSULTANT,
  },
];

export const CATEGORIES = [
  {
    label: 'Direct tax',
    value: 'DIRECT_TAX',
  },
  {
    label: 'Indirect tax',
    value: 'INDIRECT_TAX',
  },
  {
    label: 'Corporate law',
    value: 'COMPANY_LAW',
  },
];

export const COURT_TYPES = [
  {
    label: 'Supreme Court ',
    value: 'SUPREME_COURT',
  },
  {
    label: 'High Court',
    value: 'HIGH_COURT',
  },
  {
    label: 'Tribunal Court',
    value: 'TRIBUNAL',
  },
];

export const RefDataType = {
  call: 'CALL',
  expertSummary: 'EXPERT_SUMMARY',
  judgement: 'JUDGEMENT',
  template: 'TEMPLATE',
};

export const RefDataCallType = {
  oneToOne: 'ONE_TO_ONE',
};

export const SUMMARY_STATUS = {
  PUBLISHED: 'success',
  UNPUBLISHED: 'error',
  DRAFT: 'processing',
};

export const YEAR_FORMAT = 'YYYY';

export const SUBSCRIPTION_DOWNLOADABLE = {
  DIRECT_TAX: 'downloadableDirectTaxJudgements',
  INDIRECT_TAX: 'downloadableIndirectTaxJudgements',
  COMPANY_LAW: 'downloadableCompanyLawJudgements',
  TEMPLATES: 'downloadableTemplate',
  NOTICE_BOARD_DIRECT_TAX: 'assesseeCount',
  NOTICE_BOARD_INDIRECT_TAX: 'companyCount',
  ALL_IN_ONE: 'totalJudgements',
};
export const MAX_ORDER_LIMIT = 10000;

export const EDITOR_FORMATS = [
  'size',
  'color',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'clean',
];

export const EDITOR_MODULES = {
  toolbar: [
    [
      { size: [] },
      {
        color: [
          '#000000',
          '#e60000',
          '#ff9900',
          '#ffff00',
          '#008a00',
          '#0066cc',
          '#9933ff',
          '#ffffff',
          '#facccc',
          '#ffebcc',
          '#ffffcc',
          '#cce8cc',
          '#cce0f5',
          '#ebd6ff',
        ],
      },
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
      'link',
      'clean',
    ],
  ],
};

export const SORT_BY_NAME = { sortBy: 'ASC', sortOn: 'name' };
export const SORT_BY_LABEL = { sortBy: 'DESC', sortOn: 'label' };

export const OTHER_FILTER_DROPDOWN = [
  { label: 'Without Pancard', value: 'without_pancand' },
  { label: 'Without Assessment Year', value: 'without_assessment_year' },
];

export const AI_GENERATIVE_OPTIONS = [
  { label: 'AI Generated', value: 'ai_generated' },
  { label: 'Without AI Generated', value: 'without_ai_generated' },
];

export const JUDGEMENT_AI_STATUS_SELECTION = [
  { value: 'DRAFT', label: 'Draft' },
  { value: 'PUBLISHED', label: 'Published' },
];
export const JUDGES_TYPE_OPTIONS = [
  {
    key: 'JUDICIAL_MEMBER',
    label: 'Judicial Member',
  },
  {
    key: 'ACCOUNTANT_MEMBER',
    label: 'Accountant Member',
  },
];

export const meetingType = {
  Physical: 'PHYSICAL',
  Virtual: 'VIRTUAL',
};

export const CATEGORIES_KEYS = {
  'direct-tax': 'DIRECT_TAX',
  'indirect-tax': 'INDIRECT_TAX',
  'corporate-law': 'COMPANY_LAW',
};

export const STATES_LIST = [
  { value: 'All', label: 'All' },
  ...State?.getStatesOfCountry('IN')?.map((state) => ({
    value: state?.name,
    label: state?.name,
  })),
];

export const NOTICE_BOARD_NAME = 'Noticeboard';

export const FEATURE_NOTICE_BOARD_DIRECT_TAX = 'NOTICE_BOARD_DIRECT_TAX';
export const FEATURE_NOTICE_BOARD_INDIRECT_TAX = 'NOTICE_BOARD_INDIRECT_TAX';
