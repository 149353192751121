import { useMutation, useQuery } from '@apollo/client';
import { Button, Card, Col, Form, Input, Row, Space } from 'antd';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../AppContext';
import {
  formValidatorRules,
  handleProtectedNavigation,
} from '../../common/utils';
import RouterPrompt from '../../components/RouterPrompt';
import useRouter from '../../hooks/useRouter';
import {
  CHANGE_PASSWORD,
  UPDATE_CURRENT_USER,
} from '../auth/graphql/mutations';
import { GET_CURRENT_USER } from '../auth/graphql/queries';

const { required, name, email, password } = formValidatorRules;

function Profile() {
  const { dispatch, initializeAuth, getToken } = useContext(AppContext);

  const [btnLoading, setBtnLoading] = useState(false);

  const [form] = Form?.useForm();
  const [changePasswordForm] = Form?.useForm();
  const { navigate } = useRouter();
  const idToken = getToken();
  const { data: userData } = useQuery(GET_CURRENT_USER, {
    fetchPolicy: 'network-only',
  });
  const [showPrompt, setShowPrompt] = useState(false);
  const [isPrompt, setIsPrompt] = useState(false);
  const [disablePasswordBtn, setDisablePasswordBtn] = useState(true);
  const [updateCurrentUser] = useMutation(UPDATE_CURRENT_USER, {
    onCompleted: (res) => {
      dispatch({
        type: 'SET_CURRENT_USER',
        data: res?.updateUserProfile?.data,
      });
      initializeAuth(idToken, res?.updateUserProfile?.data);
    },
    onError: () => {
      form?.setFieldsValue(userData);
      setBtnLoading(false);
    },
  });

  const [changePassword, { changingPassword }] = useMutation(CHANGE_PASSWORD, {
    onCompleted() {
      setDisablePasswordBtn(true);
      changePasswordForm?.resetFields();
    },
    onError() {
      setDisablePasswordBtn(false);
    },
  });

  const handleBack = () => {
    setIsPrompt(!handleProtectedNavigation(!showPrompt, navigate, -1));
  };

  const handleShowPrompt = () => {
    setShowPrompt(true);
  };

  const handleOk = () => {
    handleProtectedNavigation(true, navigate, -1);
  };

  const handleClose = () => {
    setIsPrompt(false);
  };

  const onFinish = async (values) => {
    setBtnLoading(true);
    setShowPrompt(false);
    const userObj = {
      firstName: values?.firstName?.trim(),
      lastName: values?.lastName?.trim(),
      // commented as of now.
      // email: values?.email?.trim(),
      // phoneNumber: values?.phoneNumber,
    };
    await updateCurrentUser({
      variables: {
        data: userObj,
        userWhere: {
          id: userData?.currentUser?.id,
        },
      },
    });
    setBtnLoading(false);
  };

  const onFinishChangePassword = (data) => {
    if (data?.oldPassword && data?.password) {
      changePassword({
        variables: { data },
      });
    }
  };

  return (
    <>
      {userData?.currentUser && (
        <Form
          form={form}
          className="sticky-action-form"
          onFieldsChange={handleShowPrompt}
          layout="vertical"
          initialValues={userData?.currentUser}
          onFinish={onFinish}
        >
          <Card
            className="ant-body-scroll"
            title="Profile"
            actions={[
              <div key="action-button" className="text-right">
                <Space>
                  <Button onClick={handleBack} disabled={btnLoading}>
                    Cancel
                  </Button>
                  <Button type="primary" loading={btnLoading} htmlType="submit">
                    Save
                  </Button>
                </Space>
              </div>,
            ]}
          >
            <div className="card-body-wrapper">
              <Row gutter={[16, 16]}>
                <Col xs={24} lg={8} xl={8}>
                  <Form.Item
                    name="firstName"
                    label="First Name"
                    rules={[
                      { ...required, message: 'Please enter first name' },
                      name,
                    ]}
                  >
                    <Input placeholder="Enter first name" />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={8} xl={8}>
                  <Form.Item
                    name="lastName"
                    label="Last Name"
                    rules={[
                      { ...required, message: 'Please enter last lame' },
                      name,
                    ]}
                  >
                    <Input placeholder="Enter last name" />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={8} xl={8}>
                  <Form.Item
                    name="email"
                    label="Email Id"
                    rules={[
                      { ...required, message: 'Please enter email' },
                      email,
                    ]}
                  >
                    <Input disabled placeholder="Enter email" />
                  </Form.Item>
                </Col>
                {/* Commented for future use */}
                {/* <Col xs={24} lg={8} xl={8}>
                  <Form.Item
                    name="phoneNumber"
                    label="Phone Number"
                    rules={[
                      { ...required, message: 'Please enter mobile number' },
                      mobileNumber,
                    ]}
                  >
                    <Input placeholder="Enter Number" />
                  </Form.Item>
                </Col> */}
                {/* <Col xs={24} lg={8} xl={8}>
                      <Form.Item name="phoneNumber" label="Phone Number">
                        <Input placeholder="Enter Number" />
                      </Form.Item>
                    </Col> */}
              </Row>
            </div>
          </Card>
        </Form>
      )}
      <Form
        className="sticky-action-form mt-16"
        onFieldsChange={handleShowPrompt}
        layout="vertical"
        onFinish={onFinishChangePassword}
        form={changePasswordForm}
        onValuesChange={() => {
          setDisablePasswordBtn(false);
        }}
      >
        <Card
          className="ant-body-scroll"
          title="Change Password"
          actions={[
            <div key="action-button" className="text-right">
              <Space>
                <Button
                  onClick={handleBack}
                  disabled={btnLoading}
                  loading={changingPassword}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  loading={changingPassword}
                  htmlType="submit"
                  disabled={disablePasswordBtn}
                >
                  Save
                </Button>
              </Space>
            </div>,
          ]}
        >
          <div className="card-body-wrapper">
            <Row gutter={[16, 16]}>
              <Col xs={24} lg={8} xl={8}>
                <Form.Item
                  name="oldPassword"
                  label="Old Password"
                  rules={[{ required }]}
                >
                  <Input.Password placeholder="Enter Old password" allowClear />
                </Form.Item>
              </Col>
              <Col xs={24} lg={8} xl={8}>
                <Form.Item
                  name="password"
                  label="New Password"
                  rules={[...password, { required }]}
                >
                  <Input.Password placeholder="Enter New password" allowClear />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Card>
      </Form>
      <RouterPrompt
        isPrompt={isPrompt}
        handleOK={handleOk}
        handleCancel={handleClose}
      />
    </>
  );
}
export default Profile;
