import React from 'react';
import { ReactComponent as CallNotificationIcon } from './call-notification.svg';
import { ReactComponent as ErrorIcon } from './error.svg';
import { ReactComponent as Logo } from './logo.svg';
import { ReactComponent as NewNotificationBell } from './new-notification.svg';
import { ReactComponent as NotificationIcon } from './notification-icon.svg';
import { ReactComponent as PackageRegular } from './package-regular.svg';
import { ReactComponent as ServiceBox } from './service-box.svg';

function UsersIcon() {
  return (
    <>
      <svg
        stroke="currentColor"
        fill="#006FC0"
        strokeWidth="0"
        viewBox="0 0 640 512"
        height="50px"
        width="50px"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm448 0c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm32 32h-64c-17.6 0-33.5 7.1-45.1 18.6 40.3 22.1 68.9 62 75.1 109.4h66c17.7 0 32-14.3 32-32v-32c0-35.3-28.7-64-64-64zm-256 0c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zm-223.7-13.4C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z" />
      </svg>
    </>
  );
}

function VerifiedUsersIcon() {
  return (
    <>
      <svg
        stroke="currentColor"
        fill="#006FC0"
        strokeWidth="0"
        viewBox="0 0 640 512"
        height="50px"
        width="50px"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M622.3 271.1l-115.2-45c-4.1-1.6-12.6-3.7-22.2 0l-115.2 45c-10.7 4.2-17.7 14-17.7 24.9 0 111.6 68.7 188.8 132.9 213.9 9.6 3.7 18 1.6 22.2 0C558.4 489.9 640 420.5 640 296c0-10.9-7-20.7-17.7-24.9zM496 462.4V273.3l95.5 37.3c-5.6 87.1-60.9 135.4-95.5 151.8zM224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm96 40c0-2.5.8-4.8 1.1-7.2-2.5-.1-4.9-.8-7.5-.8h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c6.8 0 13.3-1.5 19.2-4-54-42.9-99.2-116.7-99.2-212z" />
      </svg>
    </>
  );
}

function InActiveUsersIcon() {
  return (
    <>
      <svg
        stroke="currentColor"
        fill="#006FC0"
        strokeWidth="0"
        viewBox="0 0 640 512"
        height="50px"
        width="50px"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L353.3 251.6C407.9 237 448 187.2 448 128C448 57.3 390.7 0 320 0C250.2 0 193.5 55.8 192 125.2L38.8 5.1zM264.3 304.3C170.5 309.4 96 387.2 96 482.3c0 16.4 13.3 29.7 29.7 29.7H514.3c3.9 0 7.6-.7 11-2.1l-261-205.6z" />
      </svg>
    </>
  );
}

function ActiveUsersIcon() {
  return (
    <>
      <svg
        stroke="currentColor"
        fill="#006FC0"
        strokeWidth="0"
        viewBox="0 0 512 512"
        height="50px"
        width="50px"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M256 288c79.5 0 144-64.5 144-144S335.5 0 256 0 112 64.5 112 144s64.5 144 144 144zm128 32h-55.1c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16H128C57.3 320 0 377.3 0 448v16c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48v-16c0-70.7-57.3-128-128-128z" />
      </svg>
    </>
  );
}

function UnverifiedUserIcon() {
  return (
    <>
      <svg
        stroke="currentColor"
        fill="#006FC0"
        strokeWidth="0"
        viewBox="0 0 640 512"
        height="50px"
        width="50px"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M496 224c-79.6 0-144 64.4-144 144s64.4 144 144 144 144-64.4 144-144-64.4-144-144-144zm64 150.3c0 5.3-4.4 9.7-9.7 9.7h-60.6c-5.3 0-9.7-4.4-9.7-9.7v-76.6c0-5.3 4.4-9.7 9.7-9.7h12.6c5.3 0 9.7 4.4 9.7 9.7V352h38.3c5.3 0 9.7 4.4 9.7 9.7v12.6zM320 368c0-27.8 6.7-54.1 18.2-77.5-8-1.5-16.2-2.5-24.6-2.5h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h347.1c-45.3-31.9-75.1-84.5-75.1-144zm-96-112c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128z" />
      </svg>
    </>
  );
}
export {
  ActiveUsersIcon,
  CallNotificationIcon,
  ErrorIcon,
  InActiveUsersIcon,
  Logo,
  NewNotificationBell,
  NotificationIcon,
  PackageRegular,
  ServiceBox,
  UnverifiedUserIcon,
  UsersIcon,
  VerifiedUsersIcon,
};

export const DraftIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="19"
    viewBox="0 0 30 24"
    fill="none"
  >
    <path
      d="M12.002 2.25C10.0736 2.25 8.18853 2.82183 6.58515 3.89317C4.98177 4.96451 3.73209 6.48726 2.99413 8.26884C2.25618 10.0504 2.0631 12.0108 2.4393 13.9021C2.81551 15.7934 3.7441 17.5307 5.10767 18.8943C6.47123 20.2579 8.20851 21.1865 10.0998 21.5627C11.9911 21.9389 13.9515 21.7458 15.7331 21.0078C17.5147 20.2699 19.0374 19.0202 20.1088 17.4168C21.1801 15.8134 21.752 13.9284 21.752 12C21.7492 9.41498 20.7211 6.93661 18.8932 5.10872C17.0653 3.28084 14.587 2.25273 12.002 2.25ZM12.002 20.25C10.3703 20.25 8.77521 19.7661 7.4185 18.8596C6.0618 17.9531 5.00437 16.6646 4.37995 15.1571C3.75553 13.6496 3.59215 11.9908 3.91048 10.3905C4.22881 8.79016 5.01454 7.32015 6.16833 6.16637C7.32211 5.01259 8.79212 4.22685 10.3925 3.90852C11.9928 3.59019 13.6516 3.75357 15.1591 4.37799C16.6666 5.00242 17.9551 6.05984 18.8616 7.41655C19.7681 8.77325 20.252 10.3683 20.252 12C20.2495 14.1873 19.3795 16.2843 17.8328 17.8309C16.2862 19.3775 14.1892 20.2475 12.002 20.25ZM18.002 12C18.002 12.1989 17.9229 12.3897 17.7823 12.5303C17.6416 12.671 17.4509 12.75 17.252 12.75H12.002C11.803 12.75 11.6123 12.671 11.4716 12.5303C11.331 12.3897 11.252 12.1989 11.252 12V6.75C11.252 6.55109 11.331 6.36032 11.4716 6.21967C11.6123 6.07902 11.803 6 12.002 6C12.2009 6 12.3916 6.07902 12.5323 6.21967C12.6729 6.36032 12.752 6.55109 12.752 6.75V11.25H17.252C17.4509 11.25 17.6416 11.329 17.7823 11.4697C17.9229 11.6103 18.002 11.8011 18.002 12Z"
      fill="#161616"
    />
  </svg>
);

export function DoubleCheckIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 30 24"
      fill="none"
    >
      <path
        d="M14.0275 8.03497L5.62751 16.285C5.48723 16.423 5.29835 16.5003 5.10158 16.5003C4.90481 16.5003 4.71592 16.423 4.57564 16.285L0.975639 12.7497C0.905403 12.6806 0.849458 12.5984 0.810998 12.5077C0.772539 12.417 0.752318 12.3196 0.751491 12.2211C0.750664 12.1226 0.769247 12.0249 0.806178 11.9336C0.843109 11.8423 0.897666 11.7591 0.966733 11.6889C1.0358 11.6186 1.11803 11.5627 1.20871 11.5242C1.2994 11.4858 1.39678 11.4656 1.49528 11.4647C1.59378 11.4639 1.69148 11.4825 1.7828 11.5194C1.87413 11.5563 1.95728 11.6109 2.02751 11.68L5.10158 14.6987L12.9766 6.96434C13.1185 6.82486 13.3101 6.74748 13.5091 6.74924C13.6077 6.75011 13.7051 6.77038 13.7958 6.8089C13.8865 6.84741 13.9688 6.90342 14.0378 6.97372C14.1069 7.04402 14.1614 7.12723 14.1983 7.21861C14.2353 7.30999 14.2538 7.40774 14.2529 7.50628C14.2521 7.60483 14.2318 7.70224 14.1933 7.79295C14.1548 7.88366 14.0987 7.9659 14.0285 8.03497H14.0275ZM23.0369 6.97372C22.9679 6.90337 22.8856 6.84731 22.7949 6.80876C22.7042 6.77021 22.6068 6.74992 22.5082 6.74905C22.4096 6.74818 22.3119 6.76675 22.2205 6.80369C22.1291 6.84063 22.0459 6.89522 21.9756 6.96434L14.1006 14.6987L12.3353 12.9643C12.1934 12.825 12.0018 12.7477 11.8029 12.7496C11.604 12.7514 11.4139 12.8322 11.2745 12.9742C11.1352 13.1162 11.0579 13.3077 11.0598 13.5066C11.0616 13.7056 11.1424 13.8956 11.2844 14.035L13.5747 16.285C13.715 16.423 13.9039 16.5003 14.1006 16.5003C14.2974 16.5003 14.4863 16.423 14.6266 16.285L23.0266 8.03497C23.097 7.96599 23.1531 7.88381 23.1917 7.79313C23.2304 7.70245 23.2508 7.60504 23.2517 7.50648C23.2527 7.40792 23.2342 7.31013 23.1973 7.21872C23.1605 7.1273 23.1059 7.04405 23.0369 6.97372Z"
        fill="#1677ff"
      />
    </svg>
  );
}