import { ExclamationCircleFilled, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Radio, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { mongoClient } from '../../apollo';
import {
  CATEGORIES_KEYS,
  COURT_LIST,
  JUDGES_TYPE,
  LIMIT,
  MODULES,
  PAGE_SIZE_OPTIONS,
  ROUTES,
  TAX_CATEGORIES,
} from '../../common/constants';
import SearchComponent from '../../components/SearchComponent';
import useRouter from '../../hooks/useRouter';
import MergeDataModal from './components/MergeDataModal';
import { DELETE_JUDGE } from './graphql/mutations';
import { LIST_JUDGES } from './graphql/queries';

const { Title } = Typography;
const { confirm: deleteConfirm } = Modal;

const visibilityOptions = [
  {
    text: 'Visible',
    value: true,
  },
  {
    text: 'Not Visible',
    value: false,
  },
];

function JudgesList() {
  const { navigate } = useRouter();
  const { category } = useParams();
  const [filter, setFilter] = useState({
    limit: LIMIT,
    skip: 1,
    search: '',
    isVisible: null,
  });
  const [sort, setSort] = useState({ sortBy: 'DESC', sortOn: 'createdAt' });
  const [judgesData, setJudgesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isMergeData, setIsMergeData] = useState(false);

  const listJudges = async ({ variables }) => {
    await mongoClient
      ?.query({
        query: LIST_JUDGES,
        fetchPolicy: 'network-only',
        variables,
      })
      .then((res) => {
        setJudgesData(res?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchJudgeCall = () => {
    setLoading(true);
    const { skip, limit, search } = filter;
    const prepareFilterValue = {
      skip: (skip - 1) * limit,
      limit,
      search,
      category: CATEGORIES_KEYS[category],
      court: filter?.court,
    };
    if (typeof filter?.isVisible === 'boolean') {
      prepareFilterValue.isVisible = filter?.isVisible;
    }
    listJudges({
      variables: {
        filter: prepareFilterValue,
        sort,
      },
    });
  };

  useEffect(() => {
    fetchJudgeCall();
  }, [filter, sort, category]);

  const deleteCall = async (id) => {
    const { skip, limit, search } = filter;
    setLoading(true);
    await mongoClient
      ?.mutate({
        mutation: DELETE_JUDGE,
        variables: {
          where: {
            id,
          },
        },
      })
      .then(() => {
        const prepareFilterValue = {
          skip: (skip - 1) * limit,
          limit,
          search,
          category: CATEGORIES_KEYS[category],
          court: filter?.court,
        };
        if (typeof filter?.isVisible === 'boolean') {
          prepareFilterValue.isVisible = filter?.isVisible;
        }
        listJudges({
          variables: {
            filter: prepareFilterValue,
            sort,
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setFilter({
      ...filter,
      court: filters?.court?.[0],
      skip: pagination?.current,
      limit: pagination?.pageSize,
      isVisible: filters?.isVisible === null ? null : filters?.isVisible?.[0],
    });
    setSort({
      sortOn: sorter?.order ? sorter?.field : 'createdAt',
      sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
    });
  };

  const handleSearchChange = (value) => {
    setFilter({ ...filter, skip: 1, search: value });
  };

  const sortOrder = sort.sortBy === 'DESC' ? 'descend' : 'ascend';

  const filterCourtPopup = () => ({
    filterMultiple: false,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        {Object?.keys(COURT_LIST)?.map((court) => (
          <div key={court}>
            <Radio
              checked={selectedKeys.includes(court)}
              onChange={(e) => {
                const updatedKeys = e.target.checked ? [court] : [];
                setSelectedKeys(updatedKeys);
              }}
            >
              {COURT_LIST[court]}
            </Radio>
          </div>
        ))}
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            onClick={() => confirm()}
            className="ok-button"
          >
            Apply
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
          >
            Reset
          </Button>
        </div>
      </div>
    ),
  });

  const filterVisibilityPopup = () => ({
    filterMultiple: false,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        {visibilityOptions?.map((item) => (
          <div key={item.value}>
            <Radio
              checked={selectedKeys.includes(item.value)}
              onChange={(e) => {
                const updatedKeys = e.target.checked ? [item.value] : [];
                setSelectedKeys(updatedKeys);
              }}
            >
              {item.text}
            </Radio>
          </div>
        ))}
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            onClick={() => confirm()}
            className="ok-button"
          >
            Apply
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
          >
            Reset
          </Button>
        </div>
      </div>
    ),
  });

  const handleDelete = (id) => {
    deleteConfirm({
      centered: true,
      title: 'Are you sure to delete?',
      icon: <ExclamationCircleFilled />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteCall(id);
      },
      onCancel() {},
    });
  };

  const columns = [
    {
      title: 'Name',
      sorter: true,
      sortOrder: sort.sortOn === 'name' ? sortOrder : null,
      dataIndex: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      render(description) {
        return description ?? '-';
      },
    },

    {
      title: 'Categories',
      dataIndex: 'category',
      render(categories) {
        return categories?.map((item) => TAX_CATEGORIES[item]).join(', ');
      },
    },
    {
      title: 'Court',
      dataIndex: 'court',
      render(court) {
        return court?.length > 0 ? court?.map((item) => item).join(', ') : '-';
      },
      ellipsis: true,
      ...filterCourtPopup(),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      className: 'judge-type',
      render(type) {
        return type ? JUDGES_TYPE[type] : '-';
      },
    },
    {
      title: 'Is Visible',
      dataIndex: 'isVisible',
      filterMultiple: false,
      render(value) {
        return value ? 'True' : 'False';
      },
      key: 'isVisible',
      ...filterVisibilityPopup(),
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      render: (_, { _id: id } = {}) => (
        <div className="category-action">
          <Link to={`/${category}${ROUTES.EDIT_JUDGE}${id}`}>Edit</Link>
          <Button
            type="link"
            className="action-btn"
            onClick={() => handleDelete(id)}
            danger
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
        {MODULES.JUDGES}
        {judgesData?.judgesAdmin?.count
          ? ` (${judgesData?.judgesAdmin?.count})`
          : ''}
      </Title>
      <div className="filter-input mb-12">
        <SearchComponent className="list-search" getData={handleSearchChange} />
        <div>
          <Button
            className="ml-8"
            type="primary"
            onClick={() => setIsMergeData(true)}
          >
            Merge Data
          </Button>
          <Button
            icon={<PlusCircleOutlined />}
            className="ml-8"
            key="1"
            type="primary"
            onClick={() => {
              navigate(`/${category}${ROUTES?.ADD_JUDGE}`);
            }}
          >
            Add Judge
          </Button>
        </div>
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record?.id}
        dataSource={judgesData?.judgesAdmin?.data}
        pagination={{
          total: judgesData?.judgesAdmin?.count,
          current: filter.skip,
          pageSize: filter.limit,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
        }}
        loading={loading}
        scroll={{ y: `calc(100vh - 342px)` }}
        onChange={handleTableChange}
      />
      {isMergeData && (
        <MergeDataModal
          open={isMergeData}
          onCancel={() => setIsMergeData(false)}
          fetchJudgeCall={fetchJudgeCall}
        />
      )}
    </>
  );
}

export default JudgesList;
