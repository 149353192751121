import React from 'react';

const AdvanceSearchDetails = () => (
  <div className="advance-search-container">
    <p className="title">Advance Search Options</p>
    <div className="row">
      <strong className="first-col">( )</strong>
      <small className="middle-col">
        Search with multiple words separated by space
      </small>
      <small className="last-col">
        <em>capital gains</em>
      </small>
    </div>
    <div className="row">
      <strong className="first-col">" "</strong>
      <small className="middle-col">
        Search with double quote search for exact phrase
      </small>
      <small className="last-col">
        <em>"104/Ahd/2018" or "bogus purchases"</em>
      </small>
    </div>
    <div className="row">
      <strong className="first-col">" " and space</strong>
      <small className="middle-col">
        Search with words in double quote plus other words separated by space
      </small>
      <small className="last-col">
        <em>"partnership firm" remuneration</em>
      </small>
    </div>
    <div className="row">
      <strong className="first-col">?</strong>
      <small className="middle-col">
        Wildcard Character (?) - Find Similar words with variation on single
        character
      </small>
      <small className="last-col">
        <em>eg. wom?n will search woman or women</em>
      </small>
    </div>
    <div className="row">
      <strong className="first-col">*</strong>
      <small className="middle-col">
        WildCard Character ( * ) - Find all words containing root word
      </small>
      <small className="last-col">
        <em>e.g juris* will search jurisprudence as well as jurisdiction</em>
      </small>
    </div>
  </div>
);
export default AdvanceSearchDetails;
