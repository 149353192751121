import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import ImportBatch from './importBatch';
import ImportCSV from './importcsv';

const ImportCSVWrapper = () => (
  <Routes>
    <Route path={ROUTES.MAIN} exact element={<ImportCSV />} />
    <Route path={ROUTES.CREATE} exact element={<ImportBatch />} />
  </Routes>
);
export default ImportCSVWrapper;
