import { useLazyQuery } from '@apollo/client';
import { Badge } from 'antd';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AppContext } from '../../../AppContext';
import { NotificationIcon } from '../../../assets/svg';
import LoaderComponent from '../../../components/LoaderComponent';
import { GET_NOTIFICATIONS } from '../../../components/graphql/queries';
import '../../../styles/components/notification.less';
import NotificationDrawer from './NotificationDrawer';

const sort = [
  {
    sortBy: 'DESC',
    sortOn: 'createdAt',
  },
];

function Notification() {
  const { fetchCurrentUser, currentUserData } = useContext(AppContext);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const copyUserData = useMemo(() => ({ ...currentUserData }), []);
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState({
    limit: 10,
    skip: 0,
  });
  const containerRef = useRef(null);
  const [hasMore, setHasMore] = useState(true);
  const [fetchNotifications, { data, loading, fetchMore }] = useLazyQuery(
    GET_NOTIFICATIONS,
    {
      fetchPolicy: 'network-only',
      onCompleted() {
        copyUserData.hasUnseenNotification = false;
        fetchCurrentUser();
      },
      onError() {},
    },
  );

  const handleClickOutside = (event) => {
    if (
      containerRef.current &&
      !containerRef.current?.contains(event?.target)
    ) {
      setOpen(false);
    }
  };

  const handleOpenNotificationPanel = () => {
    setOpen(!open);
  };

  const getMoreNotifications = () => {
    if (loading || !hasMore) return;

    fetchMore({
      variables: {
        filter: {
          ...filter,
          skip: data?.notifications?.data?.length,
        },
        sort,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return previousResult;

        if (fetchMoreResult?.notifications?.data?.length === 0) {
          setHasMore(false);
        }
        return {
          ...previousResult,
          notifications: {
            ...previousResult?.notifications,
            data: [
              ...previousResult?.notifications?.data,
              ...fetchMoreResult?.notifications?.data,
            ],
          },
        };
      },
    });
  };

  const handleScroll = (event) => {
    const bottom =
      event?.currentTarget?.scrollHeight - event?.currentTarget?.scrollTop ===
      event?.currentTarget?.clientHeight;
    if (bottom && hasMore) {
      getMoreNotifications();
    }
  };

  useEffect(() => {
    if (open) {
      fetchNotifications({
        variables: {
          filter,
          sort,
        },
      });
      // eslint-disable-next-line no-undef
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      setHasMore(true);
      setFilter((prev) => ({ ...prev, skip: 0 }));
      // eslint-disable-next-line no-undef
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      // eslint-disable-next-line no-undef
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchNotifications, open, setFilter]);

  return (
    <div className="header-notification-container" ref={containerRef}>
      <span className="pointer h-full" onClick={handleOpenNotificationPanel}>
        {copyUserData?.hasUnseenNotification ||
        currentUserData?.unreadNotifications ? (
          <Badge
            size="default"
            count={currentUserData?.unreadNotifications ?? ''}
          >
            <NotificationIcon />
          </Badge>
        ) : (
          <NotificationIcon />
        )}
      </span>
      {open && (
        <div
          onScroll={handleScroll}
          className={`notification-drawer ${
            data?.notifications?.data?.length === 0
              ? 'd-flex align-center justify-center'
              : ''
          }`}
        >
          <LoaderComponent spinning={loading}>
            {data?.notifications?.count > 0 ? (
              <NotificationDrawer
                notifications={data?.notifications?.data}
                toggleDrawer={handleOpenNotificationPanel}
              />
            ) : (
              <div className="notification-list">
                <span>No new notifications</span>
              </div>
            )}
          </LoaderComponent>
        </div>
      )}
    </div>
  );
}

export default Notification;
