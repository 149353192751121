import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { mongoClient } from '../../apollo';
import {
  CATEGORIES_KEYS,
  LIMIT,
  MODULES,
  PAGE_SIZE_OPTIONS,
  SORT,
  SORT_OPTION,
} from '../../common/constants';
import SearchComponent from '../../components/SearchComponent';
import KeywordsModal from './components/KeywordsModal';
import GET_KEYWORD from './graphql/queries';

const { Title, Text } = Typography;

const initialFormValue = { label: '', regex: [] };
function KeywordsList() {
  const { category } = useParams();

  const [variables, setVariables] = useState({
    filter: {
      skip: 0,
      limit: LIMIT,
      search: '',
    },
    sort: { sortBy: 'DESC', sortOn: 'createdAt' },
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: LIMIT,
    pageSizeOptions: PAGE_SIZE_OPTIONS,
  });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formPopup, setFormPopup] = useState({
    isOpen: false,
    id: null,
  });
  const [initialFormData, setInitialFormData] = useState(initialFormValue);

  const fetchData = async (variablesObj = variables, pageNumber = 1) => {
    setLoading(true);
    try {
      const listData = await mongoClient?.query({
        query: GET_KEYWORD,
        fetchPolicy: 'network-only',
        variables: {
          filter: {
            ...variablesObj?.filter,
            category: CATEGORIES_KEYS[category],
          },
          sort: { ...variablesObj?.sort },
        },
      });
      setData(listData?.data?.keywordsAdmin?.data);
      setPagination({
        ...pagination,
        total: listData?.data?.keywordsAdmin?.count,
        current: pageNumber,
        pageSize: variablesObj?.filter?.limit,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [category]);

  const handleSearchChange = (e) => {
    const prepareFilter = {
      ...variables,
      filter: { ...variables.filter, search: e },
    };
    fetchData(prepareFilter);
  };

  const handleTableChange = (paginationValue, filters, sorter) => {
    const pageSizeValue = paginationValue?.pageSize ?? LIMIT;
    const prepareFilter = {
      ...variables,
      filter: {
        ...variables.filter,
        skip: (paginationValue?.current - 1) * pageSizeValue,
        limit: pageSizeValue,
      },
      sort: {
        sortOn:
          sorter.columnKey && sorter.order ? sorter.columnKey : 'createdAt',
        sortBy: sorter.order === SORT.ASC ? SORT_OPTION.ASC : SORT_OPTION.DESC,
      },
    };
    setVariables(prepareFilter);
    fetchData(prepareFilter, paginationValue?.current);
  };

  const handleClose = () => {
    setFormPopup({
      isOpen: false,
      id: null,
    });
  };

  const handleForm = async () => {
    try {
      await fetchData();
    } catch (error) {
      return;
    } finally {
      handleClose();
    }
  };

  return (
    <>
      <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
        {MODULES.KEYWORDS}
        {pagination?.total ? ` (${pagination?.total})` : ''}
      </Title>
      <div className="filter-input mb-12">
        <SearchComponent className="list-search" getData={handleSearchChange} />
        <Button
          icon={<PlusCircleOutlined />}
          className="ml-8"
          key="1"
          type="primary"
          onClick={() => {
            setFormPopup({
              isOpen: true,
              id: 'new',
            });
            setInitialFormData(initialFormValue);
          }}
        >
          Add Keyword
        </Button>
      </div>
      <Table
        columns={[
          {
            title: 'Name',
            sorter: true,
            width: '40%',
            key: 'label',
            dataIndex: 'label',
          },
          {
            title: 'Regex',
            sorter: true,
            width: '40%',
            key: 'slug',
            render(item) {
              return (
                <Text
                  ellipsis={{
                    rows: 3,
                  }}
                >
                  {item?.regex?.length ? item?.regex?.join(',') : '-'}
                </Text>
              );
            },
          },
        ]}
        // eslint-disable-next-line arrow-body-style
        onRow={(record) => {
          return {
            onClick: () => {
              setFormPopup({
                isOpen: true,
                // eslint-disable-next-line no-underscore-dangle
                id: record?._id,
              });
              setInitialFormData({
                label: record?.label,
                regex: record?.regex?.length
                  ? record?.regex?.map((item) => ({ reg: item }))
                  : [],
              });
            },
          };
        }}
        rowKey={(record) => record?.id}
        dataSource={data}
        pagination={pagination}
        loading={loading}
        scroll={{ y: `calc(100vh - 342px)` }}
        onChange={handleTableChange}
        rowClassName="pointer"
      />
      <KeywordsModal
        formPopup={formPopup}
        handleCloseModal={handleClose}
        handleModalForm={handleForm}
        initialFormData={initialFormData}
        category={category}
      />
    </>
  );
}

export default KeywordsList;
