import { gql } from '@apollo/client';

export const EDIT_JUDGEMENT_DETAILS = gql`
  mutation UpdateJudgementAdmin(
    $data: UpdateJudgementInput!
    $where: JudgementIdWhereUniqueInput!
  ) {
    updateJudgementAdmin(data: $data, where: $where) {
      message
      data {
        _id
        appealNumber
        appellant
        category
        court
        judgmentText
        respondent
        pdfUrl
        scrapePdfUrl
        dateOfOrder
        metaData
        downloadCounter
        createdAt
        updatedAt
      }
    }
  }
`;

export const UPDATE_EXPERT_SUMMARY = gql`
  mutation adminUpdateExpertSummary(
    $data: AdminUpdateExpertSummaryInput!
    $where: ExpertSummaryWhereUnique!
  ) {
    adminUpdateExpertSummary(data: $data, where: $where) {
      data {
        id
      }
      message
    }
  }
`;

export const IMPORT_JUDGEMENT = gql`
  mutation ImportJudgement($data: [importJudgementData!]!) {
    importJudgement(data: $data) {
      message
    }
  }
`;

export const DELETE_JUDGEMENT = gql`
  mutation DeleteJudgementAdmin($where: JudgementIdWhereUniqueInput!) {
    deleteJudgementAdmin(where: $where) {
      message
    }
  }
`;

export const UPDATE_SYNC_APPEAL = gql`
  mutation UpdateSyncAppeal(
    $where: JudgementIdWhereUniqueInput!
    $data: SyncJudgement!
  ) {
    updateSyncAppeal(where: $where, data: $data) {
      message
    }
  }
`;
