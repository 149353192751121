import { gql } from '@apollo/client';

export const GET_TRANSACTION_LOGS = gql`
  query transactionLogsAdmin(
    $sort: [ListTransactionLogsSort!]
    $filter: ListTransactionLogsAdminFilter
  ) {
    transactionLogsAdmin(sort: $sort, filter: $filter) {
      count
      data {
        id
        type
        status
        amount
        paidOutAt
        invoiceUrl
        billing {
          createdAt
          firstName
          lastName
          phone
          email
        }
        provider {
          id
          firstName
          lastName
          roles
        }
        template {
          id
          title
          creator {
            firstName
            lastName
          }
        }
        subscriptionPlan {
          id
          title
          features
          creator {
            firstName
            lastName
          }
        }
        consultationCall {
          id
          title
          creator {
            firstName
            lastName
          }
        }
        user {
          email
          roles
          lastName
          phoneNumber
          firstName
          id
        }
        paymentMode
        ledgers {
          label
          amount
          id
          percentage
          status
        }
        settledDate
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_TRANSACTION = gql`
  query transactionLogAdmin($data: transactionLogAdminInput) {
    transactionLogAdmin(data: $data) {
      id
      type
      status
      amount
      template {
        id
        title
        purchases {
          billing {
            firstName
            lastName
            email
            phone
            gstNumber
            referralCode
            billingName
            address
            city
            state
            pinCode
          }
        }
      }
      subscriptionPlan {
        title
        id
        purchases {
          billing {
            firstName
            lastName
            email
            phone
            gstNumber
            referralCode
            billingName
            address
            city
            state
            pinCode
          }
        }
      }
      consultationCall {
        id
        title
      }
      billing {
        firstName
        lastName
        email
        phone
        gstNumber
        referralCode
        billingName
        address
        city
        state
        pinCode
      }
      user {
        email
        roles
        lastName
        phoneNumber
        firstName
        id
      }
      creator {
        lastName
        firstName
        id
      }
      reason
      completionDate
      settledDate
      createdAt
      updatedAt
    }
  }
`;

export const GET_LOGS_TO_EXPORT = gql`
  query exportReport($data: ExportReportData!) {
    exportReport(data: $data)
  }
`;

export const GET_CONSULTANT_AMOUNT = gql`
  query ConsultantAmount($filter: ConsultantAmountFilter!) {
    consultantAmount(filter: $filter) {
      pendingAmount
      totalAmount
    }
  }
`;
