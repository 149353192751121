import {
  MinusCircleOutlined,
  PlusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Button, Form, Input, Modal, Space, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { mongoClient } from '../../apollo';
import {
  CATEGORIES_KEYS,
  LIMIT,
  MODULES,
  PAGE_SIZE_OPTIONS,
  SORT,
  SORT_OPTION,
} from '../../common/constants';
import SearchComponent from '../../components/SearchComponent';
import MergeDataModal from './components/MergeDataModal';
import { CREATE_LAWYER, UPDATE_LAWYER } from './graphql/mutations';
import GET_LAWYERS from './graphql/queries';

const { Title } = Typography;
const initialFormValue = {
  name: '',
  noSpaceName: '',
  slug: '',
  mergedNoSpaceNames: [],
};

function LawyersList() {
  const { category } = useParams();

  const [variables, setVariables] = useState({
    filter: {
      skip: 0,
      limit: LIMIT,
      search: '',
    },
    sort: { sortBy: 'DESC', sortOn: 'createdAt' },
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: LIMIT,
    pageSizeOptions: PAGE_SIZE_OPTIONS,
  });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formPopup, setFormPopup] = useState({
    isOpen: false,
    id: null,
  });
  const [isMergeData, setIsMergeData] = useState(false);

  const [form] = Form?.useForm();
  const [buttonLoader, setButtonLoader] = useState(false);

  const fetchData = async (variablesObj = variables, pageNumber = 1) => {
    setLoading(true);
    try {
      const listData = await mongoClient?.query({
        query: GET_LAWYERS,
        fetchPolicy: 'network-only',
        variables: {
          filter: {
            ...variablesObj?.filter,
            category: CATEGORIES_KEYS[category],
          },
          sort: { ...variablesObj?.sort },
        },
      });
      setData(listData?.data?.lawyersAdmin?.data);
      setPagination({
        ...pagination,
        total: listData?.data?.lawyersAdmin?.count,
        current: pageNumber,
        pageSize: variablesObj?.filter?.limit,
      });
      setVariables(variablesObj);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [category]);

  const handleSearchChange = (e) => {
    const prepareFilter = {
      ...variables,
      filter: { ...variables.filter, search: e },
    };
    setVariables(prepareFilter);
    fetchData(prepareFilter);
  };

  const handleTableChange = (paginationValue, filters, sorter) => {
    const pageSizeValue = paginationValue?.pageSize ?? LIMIT;
    const prepareFilter = {
      ...variables,
      filter: {
        ...variables.filter,
        skip: (paginationValue?.current - 1) * pageSizeValue,
        limit: pageSizeValue,
      },
      sort: {
        sortOn:
          sorter.columnKey && sorter.order ? sorter.columnKey : 'createdAt',
        sortBy: sorter.order === SORT.ASC ? SORT_OPTION.ASC : SORT_OPTION.DESC,
      },
    };
    fetchData(prepareFilter, paginationValue?.current);
  };

  const handleClose = () => {
    setFormPopup({
      isOpen: false,
      id: null,
    });
    form?.resetFields();
  };

  const handleForm = async (values) => {
    setButtonLoader(true);
    const prepareValue = {
      ...values,
      mergedNoSpaceNames: values?.mergedNoSpaceNames?.map((item) => item.reg),
      category: CATEGORIES_KEYS[category],
    };
    try {
      if (formPopup?.id === 'new') {
        await mongoClient?.mutate({
          mutation: CREATE_LAWYER,
          fetchPolicy: 'network-only',
          variables: {
            data: prepareValue,
          },
        });
      } else {
        await mongoClient?.mutate({
          mutation: UPDATE_LAWYER,
          fetchPolicy: 'network-only',
          variables: {
            data: prepareValue,
            where: {
              id: formPopup?.id,
            },
          },
        });
      }
      await fetchData();
    } catch (error) {
      return;
    } finally {
      handleClose();
      setButtonLoader(false);
    }
  };

  return (
    <>
      <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
        {MODULES.LAWYERS}
        {pagination?.total ? ` (${pagination?.total})` : ''}
      </Title>
      <div className="filter-input mb-12">
        <SearchComponent className="list-search" getData={handleSearchChange} />
        <div>
          <Button
            className="ml-8"
            type="primary"
            onClick={() => setIsMergeData(true)}
          >
            Merge Data
          </Button>
          <Button
            icon={<PlusCircleOutlined />}
            className="ml-8"
            key="1"
            type="primary"
            onClick={() => {
              setFormPopup({
                isOpen: true,
                id: 'new',
              });
              form.setFieldsValue(initialFormValue);
            }}
          >
            Add lawyer
          </Button>
        </div>
      </div>
      <Table
        columns={[
          {
            title: 'Name',
            sorter: true,
            width: '40%',
            key: 'name',
            dataIndex: 'name',
          },
          {
            title: 'Slug',
            sorter: true,
            width: '40%',
            key: 'slug',
            dataIndex: 'slug',
          },
        ]}
        rowKey={(record) => record?.id}
        // eslint-disable-next-line arrow-body-style
        onRow={(record) => {
          return {
            onClick: () => {
              setFormPopup({
                isOpen: true,
                // eslint-disable-next-line no-underscore-dangle
                id: record?._id,
              });

              form.setFieldsValue({
                name: record?.name,
                noSpaceName: record?.noSpaceName,
                slug: record?.slug,
                mergedNoSpaceNames: record?.mergedNoSpaceNames.length
                  ? record?.mergedNoSpaceNames?.map((item) => ({
                      reg: item,
                    }))
                  : [],
              });
            },
          };
        }}
        dataSource={data}
        pagination={pagination}
        loading={loading}
        scroll={{ y: `calc(100vh - 342px)` }}
        onChange={handleTableChange}
        rowClassName="pointer"
      />
      <Modal
        destroyOnClose
        title={`${formPopup?.id === 'new' ? 'Add Lawyer' : 'Update Lawyer'}`}
        open={formPopup?.isOpen}
        onCancel={handleClose}
        width={650}
        footer={[
          <Button key="submit" onClick={handleClose}>
            Cancel
          </Button>,
          <Button
            key="back"
            type="primary"
            loading={buttonLoader}
            onClick={() => form?.submit()}
          >
            Save
          </Button>,
        ]}
      >
        <div>
          <Form
            form={form}
            onFinish={handleForm}
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 17 }}
          >
            <Form.Item
              name="name"
              label="Lawyer Name"
              rules={[
                {
                  required: true,
                  message: 'Please enter Lawyer Name',
                },
                {
                  whitespace: true,
                  message: 'Invalid input',
                },
              ]}
            >
              <Input placeholder="Enter Lawyer Name" />
            </Form.Item>
            <Form.Item name="noSpaceName" label="No Space Name">
              <Input placeholder="Enter No Space Name" />
            </Form.Item>
            <Form.Item name="slug" label="Slug">
              <Input placeholder="Enter Slug" />
            </Form.Item>
            <Form.Item label="Merged No Space Names">
              <Form.List
                name="mergedNoSpaceNames"
                label="Merged No Space Names"
              >
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        className="dynamic-cat-input"
                        align="baseline"
                      >
                        <Form.Item
                          {...restField}
                          key={key}
                          name={[name, 'reg']}
                          rules={[
                            {
                              required: true,
                              message: 'Please enter Space Name',
                            },
                            {
                              whitespace: true,
                              message: 'Invalid input',
                            },
                          ]}
                        >
                          <Input placeholder="Enter Space Name" />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add Space Name
                      </Button>
                    </Form.Item>
                    <Form.ErrorList
                      className="category-error"
                      errors={errors}
                    />
                  </>
                )}
              </Form.List>
            </Form.Item>
          </Form>
        </div>
      </Modal>
      {isMergeData && (
        <MergeDataModal
          open={isMergeData}
          onCancel={() => setIsMergeData(false)}
          fetchData={fetchData}
          category={category}
        />
      )}
    </>
  );
}

export default LawyersList;
