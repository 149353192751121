import { ExclamationCircleFilled, InfoCircleOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Button, Modal, Popover, Radio, Table, Typography } from 'antd';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { mongoClient } from '../../apollo';
import {
  CATEGORIES_KEYS,
  COURT_LIST,
  LIMIT,
  MODULES,
  PAGE_SIZE_OPTIONS,
  ROLE_KEYS,
  ROUTES,
  TAX_CATEGORIES,
  dateTimeFormat,
} from '../../common/constants';
import SearchComponent from '../../components/SearchComponent';
import AdvanceSearchDetails from './components/AdvanceSearchDetails';
import { DELETE_JUDGEMENT } from './graphql/mutations';
import { LIST_JUDGEMENTS, SEND_TEST_NEWSLETTER } from './graphql/queries';

const { Title } = Typography;
const { confirm: deleteConfirm } = Modal;

function JudgementsList() {
  const { category: categoryType } = useParams();
  const {
    state: { currentUser },
  } = useContext(AppContext);
  const [filter, setFilter] = useState({ limit: LIMIT, skip: 1, search: '' });
  const [sort, setSort] = useState({ sortBy: 'DESC', sortOn: 'createdAt' });
  const [judgements, setJudgements] = useState([]);
  const [loading, setLoading] = useState(true);
  const isStaff = useMemo(() => currentUser?.roles.includes(ROLE_KEYS.STAFF), [
    currentUser?.roles,
  ]);

  const [sendNewsletter, { loading: newsletterLoading }] = useLazyQuery(
    SEND_TEST_NEWSLETTER,
    {
      fetchPolicy: 'network-only',
    },
  );

  const listJudgments = async ({ variables }) => {
    await mongoClient
      ?.query({
        query: LIST_JUDGEMENTS,
        fetchPolicy: 'network-only',
        variables,
      })
      .then((res) => {
        setLoading(false);
        setJudgements(res?.data);
      })
      ?.catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    const { skip, limit, search } = filter;
    listJudgments({
      variables: {
        filter: {
          skip: (skip - 1) * limit,
          limit,
          search,
          category: CATEGORIES_KEYS[categoryType],
          court: filter?.court,
        },
        sort,
      },
    });
  }, [filter, sort, categoryType]);

  const handleTableChange = (pagination, filters, sorter) => {
    setFilter({
      ...filter,
      court: filters?.court?.[0],
      skip: pagination?.current,
      limit: pagination?.pageSize,
    });
    setSort({
      sortOn: sorter?.order ? sorter?.field : 'createdAt',
      sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
    });
  };

  const handleSearchChange = (value) => {
    setFilter({ ...filter, skip: 1, search: value });
  };

  const filterCourtPopup = () => ({
    filterMultiple: false,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        {Object?.keys(COURT_LIST)?.map((court) => (
          <div key={court}>
            <Radio
              checked={selectedKeys.includes(court)}
              onChange={(e) => {
                const updatedKeys = e.target.checked ? [court] : [];
                setSelectedKeys(updatedKeys);
              }}
            >
              {COURT_LIST[court]}
            </Radio>
          </div>
        ))}
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            onClick={() => confirm()}
            className="ok-button"
          >
            Apply
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
          >
            Reset
          </Button>
        </div>
      </div>
    ),
  });

  const deleteJudgement = async (id) => {
    setLoading(true);
    const { skip, limit, search } = filter;
    await mongoClient
      ?.mutate({
        mutation: DELETE_JUDGEMENT,
        variables: {
          where: {
            id,
          },
        },
      })
      .then(() => {
        const prepareFilterValue = {
          skip: (skip - 1) * limit,
          limit,
          search,
          category: CATEGORIES_KEYS[categoryType],
        };
        listJudgments({
          variables: {
            filter: prepareFilterValue,
            sort,
          },
        });
      });
  };

  const handleDelete = (id) => {
    deleteConfirm({
      centered: true,
      title: 'Are you sure to delete?',
      icon: <ExclamationCircleFilled />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      okButtonProps: { loading },
      onOk() {
        deleteJudgement(id);
      },
      onCancel() {},
    });
  };

  const columns = [
    {
      title: 'Appeal Number',
      dataIndex: 'appealNumber',
    },
    {
      title: 'Appellant',
      dataIndex: 'appellant',
      render(appellant) {
        return appellant ?? '-';
      },
    },
    {
      title: 'Categories',
      dataIndex: 'category',
      render(category) {
        return TAX_CATEGORIES[category];
      },
    },
    {
      title: 'Court',
      dataIndex: 'court',
      render(court) {
        return court ?? '-';
      },
      ...filterCourtPopup(),
    },
    {
      title: 'Judgement Text',
      dataIndex: 'judgmentText',
      ellipsis: true,
      render(judgmentText) {
        return judgmentText ?? '-';
      },
    },
    // kept for future use
    // {
    //   title: 'PDF',
    //   dataIndex: 'pdfUrl',
    //   ellipsis: true,
    //   render(url) {
    //     return (
    //       <Link to={url} target="_blank" rel="noreferrer">
    //         {url}
    //       </Link>
    //     );
    //   },
    // },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      sorter: true,
      render(date) {
        return dayjs(date).format(dateTimeFormat);
      },
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      render(date) {
        return dayjs(date).format(dateTimeFormat);
      },
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      render: (_, { id } = {}) => (
        <div className="category-action">
          <Link to={`/${categoryType}${ROUTES.EDIT_JUDGEMENTS}${id}`}>
            {isStaff ? 'View' : 'Edit'}
          </Link>
          {!isStaff && (
            <Button
              type="link"
              className="action-btn"
              onClick={() => handleDelete(id)}
              danger
            >
              Delete
            </Button>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
        {MODULES.JUDGEMENTS}
        {judgements?.judgmentsAdmin?.judgementsCount
          ? ` (${judgements?.judgmentsAdmin?.judgementsCount})`
          : ''}
      </Title>
      <div className="d-flex filter-input gap-16 justify-between mb-12">
        <div className="d-flex align-center justify-center gap-8">
          <SearchComponent
            className="list-search"
            getData={handleSearchChange}
            disabled={filter.search === '' && loading}
          />
          <div className="advance-search-info-btn">
            <Popover
              content={AdvanceSearchDetails}
              trigger="hover"
              placement="bottom"
            >
              <InfoCircleOutlined />
            </Popover>
          </div>
        </div>
        <div className="d-flex gap-16">
          {process.env.REACT_APP_ALLOW_IMPORT_JUDGEMENT === 'true' && !isStaff && (
            <Link
              to={`/${categoryType}${ROUTES.JUDGEMENTS}${ROUTES.IMPORT_JUDGEMENT}`}
            >
              <Button type="primary">Import Judgment</Button>
            </Link>
          )}
          <Button
            type="primary"
            loading={newsletterLoading}
            onClick={sendNewsletter}
          >
            Test Newsletter
          </Button>
        </div>
        {/* Commented for future use */}
        {/* <Button
          icon={<PlusCircleOutlined />}
          className="ml-8"
          key="1"
          type="primary"
          onClick={() => {
            navigate(ROUTES?.ADD_JUDGEMENT);
          }}

        >
          Add judgement
        </Button> */}
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record?.id}
        dataSource={judgements?.judgmentsAdmin?.data}
        pagination={{
          total: judgements?.judgmentsAdmin?.count,
          current: filter.skip,
          pageSize: filter.limit,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
        }}
        loading={loading}
        scroll={{ y: `calc(100vh - 342px)` }}
        onChange={handleTableChange}
      />
    </>
  );
}

export default JudgementsList;
