import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import {
  Button,
  Modal,
  Radio,
  Select,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { mongoClient } from '../../apollo';
import {
  IMPORT_CSV_STATUS,
  IMPORT_CSV_VISIBILITY,
  JUDGEMENT_AI_STATUS_SELECTION,
  LIMIT,
  PAGE_SIZE_OPTIONS,
  ROUTES,
  STANDARD_DATE_FORMAT,
} from '../../common/constants';
import SearchComponent from '../../components/SearchComponent';
import useRouter from '../../hooks/useRouter';
import { DELETE_BATCH, UPDATE_CSV_STATUS } from './graphql/mutations';
import { IMPORT_APPEALS_HISTORIES } from './graphql/queries';
import './importcsv.less';

const { Title } = Typography;
const { confirm: deleteConfirm } = Modal;

const IMPORT_CSV_STATUS_COLORS = {
  COMPLETED: {
    color: 'success',
    icon: <CheckCircleOutlined style={{ fontSize: '12px' }} />,
  },
  PENDING: {
    color: 'warning',
    icon: <ClockCircleOutlined style={{ fontSize: '12px' }} />,
  },
  IN_PROCESS: {
    color: 'yellow',
    icon: <ClockCircleOutlined style={{ fontSize: '12px' }} />,
  },
  ERROR: {
    color: 'error',
    icon: <CloseCircleOutlined style={{ fontSize: '12px' }} />,
  },
  FAILED: {
    color: 'error',
    icon: <CloseCircleOutlined style={{ fontSize: '12px' }} />,
  },
};

function ImportCSV() {
  const { navigate } = useRouter();
  const [filter, setFilter] = useState({
    limit: LIMIT,
    skip: 1,
    search: '',
    status: null,
    visibility: null,
    isDeleted: false,
  });
  const [sort, setSort] = useState({ sortBy: 'DESC', sortOn: 'createdAt' });
  const [importList, setImportList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  const listImportAppealsList = async ({ variables }) => {
    setLoading(true);
    await mongoClient
      ?.query({
        query: IMPORT_APPEALS_HISTORIES,
        fetchPolicy: 'network-only',
        variables,
      })
      .then((res) => {
        setImportList(res?.data?.importAppealsHistories?.data);
        setTotalCount(res?.data?.importAppealsHistories?.count);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const { skip, limit } = filter;
    const prepareFilterValue = {
      ...filter,
      skip: (skip - 1) * limit,
    };
    listImportAppealsList({
      variables: {
        filter: prepareFilterValue,
        sort: [sort],
      },
    });
  }, [filter, sort]);

  const filterStatusPopup = (options) => ({
    filterMultiple: false,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        {Object?.keys(options)?.map((item) => (
          <div key={item}>
            <Radio
              checked={selectedKeys.includes(item)}
              onChange={(e) => {
                const updatedKeys = e.target.checked ? [item] : [];
                setSelectedKeys(updatedKeys);
              }}
            >
              {options[item]}
            </Radio>
          </div>
        ))}
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            onClick={() => confirm()}
            className="ok-button"
          >
            Apply
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
          >
            Reset
          </Button>
        </div>
      </div>
    ),
  });

  const handleDelete = (id) => {
    deleteConfirm({
      centered: true,
      title: 'Are you sure to delete this Batch?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      async onOk() {
        await mongoClient?.mutate({
          mutation: DELETE_BATCH,
          fetchPolicy: 'network-only',
          variables: {
            where: {
              batchId: id,
            },
          },
        });
        const { skip, limit } = filter;
        const prepareFilterValue = {
          ...filter,
          skip: (skip - 1) * limit,
        };
        listImportAppealsList({
          variables: {
            filter: prepareFilterValue,
            sort: [sort],
          },
        });
      },
      onCancel() {},
    });
  };

  const columns = [
    {
      title: 'Batch ID',
      dataIndex: 'batchId',
      sorter: true,
      render(_, { batchId, status }) {
        return status !== 'COMPLETED' ? (
          <span>{batchId}</span>
        ) : (
          <Link
            to={`${ROUTES.REVIEW_APPEALS}?batchId=${batchId}`}
            target="_blank"
          >
            {batchId}
          </Link>
        );
      },
    },
    {
      title: 'File Size',
      dataIndex: 'fileSize',
      align: 'center',
    },
    {
      title: 'Total Records',
      dataIndex: 'totalRecords',
      width: '150px',
      align: 'center',
    },
    {
      title: 'Duplicate Count',
      dataIndex: 'duplicateCount',
      width: '150px',
      align: 'center',
      render: (item) => item ?? 0,
    },
    {
      title: (
        <Tooltip title="Judgment text with less than 500 words">
          Skip Judgement Count
        </Tooltip>
      ),
      dataIndex: 'skipJudgementCount',
      width: '200px',
      align: 'center',
      render: (item) => item ?? 0,
    },
    {
      title: 'Error Log',
      dataIndex: 'errorLog',
      width: '150px',
      align: 'center',
      render: (item) =>
        item ? (
          <a target="_blank" href={item} rel="noreferrer">
            Download
          </a>
        ) : (
          '-'
        ),
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      width: '150px',
      render(value) {
        return dayjs(value).format(STANDARD_DATE_FORMAT);
      },
      sorter: true,
      defaultSortOrder: 'descend',
      align: 'center',
    },
    {
      title: 'Batch Status',
      dataIndex: 'status',
      align: 'center',
      ...filterStatusPopup(IMPORT_CSV_STATUS),
      render: (value) => (
        <Tag
          icon={IMPORT_CSV_STATUS_COLORS?.[value]?.icon}
          color={IMPORT_CSV_STATUS_COLORS?.[value]?.color}
        >
          {value === 'IN_PROCESS' ? 'PROCESSING' : value}
        </Tag>
      ),
    },
    {
      title: 'Judgement Status',
      dataIndex: 'visibility',
      width: '170px',
      align: 'center',
      ...filterStatusPopup(IMPORT_CSV_VISIBILITY),
      render: (value, { batchId, status }) => {
        const onChange = async (event) => {
          await mongoClient?.mutate({
            mutation: UPDATE_CSV_STATUS,
            fetchPolicy: 'network-only',
            variables: {
              data: {
                visibility: event,
              },
              where: {
                batchId,
              },
            },
          });
          const { skip, limit } = filter;
          const prepareFilterValue = {
            ...filter,
            skip: (skip - 1) * limit,
          };
          listImportAppealsList({
            variables: {
              filter: prepareFilterValue,
              sort: [sort],
            },
          });
        };
        return (
          <Select
            style={{ width: 120 }}
            value={value === 'PROCESSING' ? 'Processing' : value}
            onChange={onChange}
            disabled={value !== 'DRAFT' || status !== 'COMPLETED'}
            options={JUDGEMENT_AI_STATUS_SELECTION}
          />
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      align: 'center',
      fixed: 'right',
      render: (_, { batchId, visibility, status }) => (
        <div className="category-action">
          <Link
            to={`${ROUTES.REVIEW_APPEALS}?batchId=${batchId}`}
            target="_blank"
          >
            <Button
              type="link"
              className="action-btn"
              disabled={status !== 'COMPLETED'}
            >
              Review
            </Button>
          </Link>
          <Button
            type="link"
            className="action-btn"
            onClick={() => handleDelete(batchId)}
            danger
            disabled={visibility === 'PUBLISHED' || status !== 'COMPLETED'}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    const prepareFilter = filter;
    if (filters.status === null) {
      delete prepareFilter?.status;
    } else {
      prepareFilter.status = filters?.status?.[0];
    }
    if (filters.visibility === null) {
      delete prepareFilter?.visibility;
    } else {
      prepareFilter.visibility = filters?.visibility?.[0];
    }
    setFilter({
      ...prepareFilter,
      skip: pagination?.current,
      limit: pagination?.pageSize,
    });
    setSort({
      sortOn: sorter?.order ? sorter?.field : 'createdAt',
      sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
    });
  };

  const handleSearchChange = (value) => {
    setFilter({ ...filter, skip: 1, search: value });
  };

  return (
    <>
      <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
        Import CSV
      </Title>
      <div className="filter-input mb-12">
        <SearchComponent className="list-search" getData={handleSearchChange} />
        <Button
          type="primary"
          onClick={() => {
            navigate(ROUTES.CREATE_CSV);
          }}
          icon={<PlusCircleOutlined />}
        >
          Add CSV
        </Button>
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record?.batchId}
        dataSource={importList}
        pagination={{
          total: totalCount,
          current: filter.skip,
          pageSize: filter.limit,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
        }}
        loading={loading}
        scroll={{ x: 'max-content', y: `calc(100vh - 342px)` }}
        onChange={handleTableChange}
      />
    </>
  );
}

export default ImportCSV;
